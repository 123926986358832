import React, { useEffect, useState } from "react";
import "./about.scss";
import parse from "html-react-parser";

import aboutImg from "../../../assets/images/about/about-semImg.png";

// component imports
import Wrapper from "../../layouts/wrapper/Wrapper";
import ButtonType2 from "../../buttons/buttonType2/ButtonType2";

// Api services
import { getLandingAbout } from "../../../apis/landing.apis";
import LandingAboutSkeleton from "../../skeletons/landingAboutSkeleton/LandingAboutSkeleton";

const About = () => {
  const location = window.location.pathname;

  const [loading, setLoading] = useState(false);
  const [aboutData, setAboutData] = useState([]);

  // get about data
  useEffect(() => {
    setLoading(true);
    getLandingAbout()
      .then((res) => {
        setAboutData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // console.log(aboutData);

  return (
    <div className="about">
      <Wrapper>
        {loading ? (
          <LandingAboutSkeleton />
        ) : (
          <div className="about__imageDesc">
            <div className="about__left">
              <div className="about__descHeader">
                <span className="about__descHeaderText">
                  {aboutData?.BANNER_TITLE}
                </span>

                <p className="about__descText">
                  {aboutData.DESCRIPTION && parse(aboutData?.DESCRIPTION)}
                </p>
              </div>

              {location === "/" ? (
                <div
                  className="about__btn"
                  onClick={() => {
                    window.location.href = "/about";
                  }}
                >
                  <ButtonType2 btnName={"About"} />
                </div>
              ) : null}
            </div>

            <div className="about__image">
              <img src={aboutData?.IMAGE} alt="" />
            </div>
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default About;
