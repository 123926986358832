import React from "react";
import "../customerPolicy/customerPolicy.scss";
import parse from "html-react-parser";

// components

const QualityPolicy = ({ data, loading }) => {
  return (
    <div className="customerPolicy">
      <h3 className="customerPolicy__headerText">{data.TITLE}</h3>

      <div className="customerPolicy__content">
        {data.DESCRIPTION && parse(data.DESCRIPTION)}

        {/* <span className="customerPolicy__contentDescText">
          SEMCORP Limited's policy is to conduct business in a way that
          consistently meets or exceeds the quality standards required by its
          stakeholders, which include customers, suppliers, end users, industry
          regulators, local and national authorities, and the communities in
          which the company operates. <br /> <br />
          The organization is devoted to consistently improving the quality of
          its operations and services.
        </span>

        <div className="customerPolicy__contentList">
          <h3 className="customerPolicy__contentListTitle">
            Service quality is a customer determination, and as such, the
            company will strive to:
          </h3>

          <ul className="customerPolicy__contentListItems">
            <li className="customerPolicy__contentListItem">
              Identify our clients' shifting requirements and expectations.
            </li>

            <li className="customerPolicy__contentListItem">
              Maintain protocols and procedures to ensure these changes are
              implemented.
            </li>

            <li className="customerPolicy__contentListItem">
              Provide services on schedule and at the lowest price.
            </li>

            <li className="customerPolicy__contentListItem">
              To be recognized as an industry leader in the quality of services
              in general contracting as well as building maintenance.
            </li>


            <li className="customerPolicy__contentListItem">
              Train and encourage all employees to actively engage in fulfilling
              the criteria of this policy.
            </li>
          </ul>
        </div>

        <span className="customerPolicy__contentDescText">
          Assuring that every project reflects our commitment to excellence, the
          cornerstones of our quality policy are continuous improvement &
          adherence to industry standards.
        </span> */}
      </div>
    </div>
  );
};

export default QualityPolicy;
