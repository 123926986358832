import React from "react";
import "./subHero.scss";

// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import { Autoplay, Navigation } from "swiper/modules";

// components
import Wrapper from "../../layouts/wrapper/Wrapper";
import HeaderAnimation from "../../amimation/headerAnimation/HeaderAnimation";

// assets
// import subHeroData from "./subHeroData";

const SubHero = ({ banner, title, desc, bg, pName, pLocation, pTags }) => {
  return (
    <div className="subHero">
      {bg ? (
        <div className="subHero__bg"></div>
      ) : (
        <div className="subHero__banner">
          <img src={banner} alt="banner" />
        </div>
      )}

      <div className="subHerotitleDesc">
        {title ? <h2 className="subHerotitle">{title}</h2> : null}

        {desc ? <p className="subHeroDesc">{desc}</p> : null}

        {pName ? (
          <div className="subHeroProject">
            <h3 className="subHeroProject__name">{pName}</h3>
            <p className="subHeroProject__location">{pLocation}</p>
            <div className="subHeroProject__tags">
              {pTags?.map((tag, i) => (
                <span key={i}>{tag}</span>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SubHero;
