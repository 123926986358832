import React from "react";
import "./aboutPageSkeleton.scss";

const SkeletonAboutPeople = () => {
  return (
    <div className="apSkeleton__container">
      <div className="apSkeleton__wrapper">
        <div className="apSkeleton__mission">
          <div className="skeleton apSkeleton__mImg"></div>

          <div className="ap__skeletonContent">
            <div className="skeleton apSkeleton__mTitle"></div>

            <div className="apSkeleton__mDesc">
              <div className="skeleton apSkeleton__mDescText"></div>
              <div className="skeleton apSkeleton__mDescText"></div>
              <div className="skeleton apSkeleton__mDescText"></div>
              <div className="skeleton apSkeleton__mDescText"></div>
            </div>

            <div className="apSkeleton__mDesc">
              <div className="skeleton apSkeleton__mDescText"></div>
              <div className="skeleton apSkeleton__mDescText"></div>
              <div className="skeleton apSkeleton__mDescText"></div>
              <div className="skeleton apSkeleton__mDescText"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonAboutPeople;
