import React from "react";
import "./appLayout.scss";

// components
import Navbar from "../../common/navbar/Navbar";
import Footer from "../../common/footer/Footer";

const AppLayout = ({ children, bgImg, bgColor }) => {
  return (
    <div className="appLayout">
      {bgImg ? (
        <div className="appLayout__bgImg">
          <img src={bgImg} className="appLayout__img" alt="" />
        </div>
      ) : null}

      <Navbar />
      <main className="appBlock">{children}</main>
      <Footer />
    </div>
  );
};

export default AppLayout;
