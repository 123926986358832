import React from "react";
import "./contactAdd.scss";

// img
import contactAddImg from "../../../assets/images/contact/contact-bg.jpg";
import { Link } from "react-router-dom";

const ContactAdd = () => {
  return (
    <div className="contactAdd">
      <div className="contactAdd__img">
        <img src={contactAddImg} alt="" />
      </div>

      <div className="contactAdd__content">
        <div className="contactAddText">
          <span>READY TO DISCUSS YOUR NEXT PROJECT?</span>
        </div>

        <Link to="/contact" className="contactAddButton link">
          <button>Get in touch</button>
        </Link>
      </div>
    </div>
  );
};

export default ContactAdd;
