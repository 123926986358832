import React, { useEffect, useState } from "react";
import "./careerPage.scss";
import { Link } from "react-router-dom";

// components
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import SubHero from "../../../components/heroSections/subHero/SubHero";
import Wrapper from "../../../components/layouts/wrapper/Wrapper";
import LineAnimation from "../../../components/amimation/lineAnimation/LineAnimation";

// data
import careerData from "../../../assets/staticData/careerData";

// Api Services
import { getJobList } from "../../../apis/career.apis";
import CareerPageSkeleton from "../../../components/skeletons/pageSkeletons/careerPageSkeletons/CareerPageSkeleton";

const CareerPage = () => {
  const [loading, setLoading] = useState(false);
  const [jobList, setJobList] = useState([]);

  useEffect(() => {
    setLoading(true);
    getJobList()
      .then((res) => {
        setJobList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // console.log(jobList);

  return (
    <AppLayout>
      <div className="careerPage pageLayout1">
        <SubHero
          banner={
            "https://images.pexels.com/photos/3760069/pexels-photo-3760069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          }
          title={"Career"}
        />

        <Wrapper>
          <div className="careerPage__container">
            {loading ? (
              <CareerPageSkeleton />
            ) : (
              <>
                {jobList?.map((dept, index) => {
                  return (
                    <div className="career__container" key={index}>
                      <div className="career__header">
                        <span className="career__headerText">
                          {dept?.DEPARTMENT_NAME}
                        </span>

                        <LineAnimation width={"30px"} />
                      </div>

                      <div className="career__rowContainer">
                        {dept?.positionList?.map((pos, i) => {
                          return (
                            <Link
                              to={`/career/${pos?.JOB_DESCRIPTION_ID}`}
                              state={{ position: pos }}
                              className="career__row"
                              key={i}
                            >
                              <div className="career__rowLeft">
                                <span>{pos?.POSITION}</span>
                              </div>
                              <div className="career__rowRight">
                                <span>{pos?.JOB_LOCATION}</span>
                                <i className="fas fa-chevron-right"></i>
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default CareerPage;
