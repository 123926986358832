const navMenuData = [
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Services",
    path: "/services/general-contracting",
  },
  {
    title: "Projects",
    path: "/project",
  },
  {
    title: "Career",
    path: "/career",
  },
  {
    title: "Contact",
    path: "/contact",
  },
];

export default navMenuData;
