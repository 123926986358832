import React, { useEffect, useState } from "react";
import "./landingProjects.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

// components
import Wrapper from "../../../../components/layouts/wrapper/Wrapper";
import SectionHeader from "../../../../components/common/sectionHeader/SectionHeader";
import ProjectCard from "../../../../components/cards/projectCard/ProjectCard";
import ProectCardSkeleton from "../../../../components/skeletons/cards/projectCardSkeleton/ProjectCardSkeleton";

// API SERVICE
import { getAllProjects } from "../../../../apis/projects.apis";

const LandingProjects = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllProjects()
      .then((res) => {
        setProjects(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  // console.log("Landing projects", projects);

  return (
    <Wrapper>
      <div className="landingProjects">
        <div className="landingProjects__headerDesc">
          <SectionHeader
            title="Selected Projects"
            btnName2={"View All"}
            link={"/project"}
          />
        </div>

        <div className="landingProjects__carouselContainer1">
          <Swiper
            className="lpc__Swiper1"
            slidesPerView={1}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            modules={[Pagination]}
          >
            {loading ? (
              <>
                <SwiperSlide>
                  <ProectCardSkeleton />
                </SwiperSlide>
                <SwiperSlide>
                  <ProectCardSkeleton />
                </SwiperSlide>
                <SwiperSlide>
                  <ProectCardSkeleton />
                </SwiperSlide>
              </>
            ) : (
              projects?.map((project, index) => (
                <SwiperSlide key={index}>
                  <ProjectCard data={project} />
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </div>
      </div>
    </Wrapper>
  );
};

export default LandingProjects;
