import React from "react";
import "./headerAnimation.scss";

import { Typewriter } from "react-simple-typewriter";

const HeaderAnimation = ({ text, color }) => {
  const length = text.length;

  return (
    // <div className="headerAnimation" style={{ "--text-length": length }}>
    //   <div className="headerAnimation__container">
    //     <div className="headerAnimationLine"></div>
    //     <div className="headerAnimation__textContainer">
    //       <p className="headerAnimation__text">{text}</p>
    //     </div>
    //   </div>
    // </div>

    <div className="headerAnimation" style={{ "--text-length": length }}>
      <div className="headerAnimation__container">
        <div className="headerAnimationLine"></div>

        <div className="headerAnimation__textContainer">
          <p
            className="headerAnimation__text"
            style={{
              color: color,
            }}
          >
            <Typewriter
              words={[text]}
              loop={1}
              cursor
              cursorStyle="_"
              typeSpeed={200}
              deleteSpeed={80}
              delaySpeed={5000}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderAnimation;
