import axios from "axios";

import {
  GENERAL_INQUERY_URL,
  SUB_CONSTRUCTOR_URL,
  ENLISTED_SUPPLIER_URL,
} from "../utils/urls";

export const applyGeneralInquery = (data) => {
  return axios({
    method: "POST",
    url: GENERAL_INQUERY_URL,
    data: data,
  });
};

export const applySubConstructor = (data) => {
  return axios({
    method: "POST",
    url: SUB_CONSTRUCTOR_URL,
    data: data,
  });
};

export const applyEnlistedSupplier = (data) => {
  return axios({
    method: "POST",
    url: ENLISTED_SUPPLIER_URL,
    data: data,
  });
};
