import React, { useEffect, useState } from "react";
import "./careerDetailsPage.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";

// components
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import SubHero from "../../../components/heroSections/subHero/SubHero";
import Wrapper from "../../../components/layouts/wrapper/Wrapper";
import PrimaryButton from "../../../components/buttons/primaryButton/PrimaryButton";
import LineAnimation from "../../../components/amimation/lineAnimation/LineAnimation";
import BrandButton from "../../../components/buttons/brandButton/BrandButton";
import ButtonType2 from "../../../components/buttons/buttonType2/ButtonType2";
import Navbar from "../../../components/common/navbar/Navbar";
import Footer from "../../../components/common/footer/Footer";

// Api Services
import { getJobDetails } from "../../../apis/career.apis";
import SkeletonCareerDetailsPage from "../../../components/skeletons/pageSkeletons/careerPageSkeletons/SkeletonCareerDetailsPage";

const CareerDetailsPage = () => {
  const location = useLocation();
  const careerData = location.state.position;
  // console.log(careerData);

  const jobPostId = useParams().jobPostId;

  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState({});

  useEffect(() => {
    setLoading(true);
    getJobDetails(jobPostId)
      .then((res) => {
        setJobDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [careerData.id]);
  // console.log(jobDetails);

  return (
    <div className="careerDetailsPage">
      <Navbar />

      <SubHero
        banner={
          "https://images.pexels.com/photos/3760069/pexels-photo-3760069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        }
        title={"Career"}
      />

      <div className="careerDetailsPage__container">
        {loading ? (
          <SkeletonCareerDetailsPage />
        ) : (
          <div className="cdp__wrapper">
            <div className="cdp__content">
              <div className="openPositionDetails__headerAndBtn">
                <div className="openPositionDetails__header">
                  <span>{jobDetails?.POSITION}</span>
                  <LineAnimation width={"60px"} />
                </div>

                <Link
                  // to={`/career/${careerData?.slug}/apply`}
                  to={`/career/${jobDetails?.POSITION_SLAG}/${jobDetails?.JOB_DESCRIPTION_ID}/apply`}
                  // state={{ position: jobDetails?.POSITION }}
                  className="openPositionDetails__btn"
                >
                  <ButtonType2 btnName="Apply Now" />
                </Link>
              </div>

              <div className="openPositionDetails__positionInfoContainer">
                <div className="positionInfos">
                  <div className="positionInfo">
                    <div className="positionInfo__header">
                      <span>Company:</span>
                    </div>

                    <div className="positionInfo__content">
                      <span>SEMCORP Limited</span>
                    </div>
                  </div>

                  <div className="positionInfo">
                    <div className="positionInfo__header">
                      <span>Location:</span>
                    </div>
                    <div className="positionInfo__content">
                      <span>{jobDetails?.JOB_LOCATION}</span>
                    </div>
                  </div>

                  <div className="positionInfo">
                    <div className="positionInfo__header">
                      <span>Work Location:</span>
                    </div>
                    <div className="positionInfo__content">
                      <span>{jobDetails?.WORK_LOCATION}</span>
                    </div>
                  </div>

                  <div className="positionInfo">
                    <div className="positionInfo__header">
                      <span>Job Department:</span>
                    </div>
                    <div className="positionInfo__content">
                      <span>{jobDetails?.DEPARTMENT_NAME}</span>
                    </div>
                  </div>

                  <div className="positionInfo">
                    <div className="positionInfo__header">
                      <span>Job Type:</span>
                    </div>
                    <div className="positionInfo__content">
                      <span>{jobDetails?.CONTRACT_TYPE}</span>
                    </div>
                  </div>

                  <div className="positionInfo">
                    <div className="positionInfo__header">
                      <span>Salary:</span>
                    </div>
                    <div className="positionInfo__content">
                      <span>{jobDetails?.SALARY}</span>
                    </div>
                  </div>
                </div>

                <div className="applicationDeadline">
                  <span>Application Deadline:</span>
                  <span>{jobDetails?.DEAD_LINE}</span>
                  {/* <span>23-05-23</span> */}
                </div>
              </div>

              {jobDetails?.jobdetailsList?.map((item, index) => {
                return (
                  <div
                    className="openPosition__commonHeaderAndContent"
                    key={index}
                  >
                    <div className="openPosition__commonHeader">
                      <span>{item?.TITLE}</span>
                      <LineAnimation width={"45px"} />
                    </div>

                    {item?.DESCRIPTION && (
                      <div className="openPosition__commonContent">
                        <span>{parse(item?.DESCRIPTION)}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default CareerDetailsPage;
