import React from "react";
import "./ourVission.scss";
import parse from "html-react-parser";

// components
import SkeletonAboutVission from "../../../../components/skeletons/pageSkeletons/aboutPageSkeletons/SkeletonAboutVission";

const OurVission = ({ data, loading }) => {
  return (
    <>
      {loading ? (
        <SkeletonAboutVission />
      ) : (
        <div className="aboutPage__missionContainer">
          <div className="apv__wrapper">
            <div
              className="missionContainer__left"
              style={{
                order: "1",
              }}
            >
              <img src={data?.IMAGE} alt="" />
            </div>

            <div className="missionContainer__right">
              <h3 className="cSectionText">{data?.BANNER_TITLE}</h3>

              {data?.DESCRIPTION && parse(data?.DESCRIPTION)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OurVission;
