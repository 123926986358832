import React, { useState } from "react";
import "./contactForms.scss";

// API services
import { applyGeneralInquery } from "../../../apis/contact.apis";

// components
import ButtonType2 from "../../../components/buttons/buttonType2/ButtonType2";
import { useNavigate } from "react-router-dom";

const GeneralInqueryForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [file, setFile] = useState(null);
  const handleFile = (e) => {
    const file = e.target.files[0];
    const validType = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];
    if (!validType.includes(file.type)) {
      setError("Invalid file type");
      return;
    }
    setError(null);
    setFile(file);
  };

  const [formInputs, setFormInputs] = useState({
    FULLNAME: "",
    COMPANY_POSITION: "",
    PHONE: "",
    EMAIL: "",
    MESSAGE: "",
  });
  const handleFormInputes = (name, e) => {
    setFormInputs({ ...formInputs, [name]: e.target.value });
  };

  const [checkbox, setCheckbox] = useState(false);
  const handleCheckbox = (e) => {
    setCheckbox(e.target.checked);
  };

  const formValidation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{11}$/;

    if (
      formInputs.FULLNAME === "" ||
      formInputs.COMPANY_POSITION === "" ||
      formInputs.PHONE === "" ||
      formInputs.EMAIL === "" ||
      formInputs.MESSAGE === ""
    ) {
      setError("Please fill all the fields");
      return false;
    }
    if (formInputs.EMAIL && !emailRegex.test(formInputs.EMAIL)) {
      setError("Invalid email address");
      return false;
    }
    if (formInputs.PHONE && !phoneRegex.test(formInputs.PHONE)) {
      setError("Phone number must be at least 11 characters");
      return false;
    }

    return true;
  };

  const handleSubmition = (e) => {
    e.preventDefault();

    setLoading(true);

    if (!formValidation()) {
      setLoading(false);
      return;
    }

    if (!checkbox) {
      setError("Please check the checkbox");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("Company_Profile", file);
    formData.append("FULLNAME", formInputs.FULLNAME);
    formData.append("COMPANY_POSITION", formInputs.COMPANY_POSITION);
    formData.append("PHONE", formInputs.PHONE);
    formData.append("EMAIL", formInputs.EMAIL);
    formData.append("MESSAGE", formInputs.MESSAGE);

    applyGeneralInquery(formData)
      .then((res) => {
        // console.log(res);
        setSuccessMessage("Your message has been sent successfully");
        setError(null);
        setLoading(false);

        setTimeout(() => {
          navigate("/confirmation");
        }, 1000);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        setError("Something went wrong");
      });
  };

  return (
    <form className="c__form" onSubmit={handleSubmition}>
      {/* <h3>General Inquery</h3> */}

      <div className="cf__inputContainer">
        <div className="input__group field">
          <input
            type="input"
            className="input__field"
            placeholder="Full Name"
            name="name"
            id="name"
            onChange={(e) => handleFormInputes("FULLNAME", e)}
            required
          />
          <label htmlFor="name" className="input__label">
            Full Name
          </label>
        </div>

        <div className="input__group field">
          <input
            type="input"
            className="input__field"
            placeholder="Company Position"
            name="companyPosition"
            id="companyPosition"
            onChange={(e) => handleFormInputes("COMPANY_POSITION", e)}
            required
          />
          <label htmlFor="companyPosition" className="input__label">
            Company Position
          </label>
        </div>

        <div className="input__group field">
          <input
            type="number"
            className="input__field"
            placeholder="Phone Number"
            name="phoneNumber"
            id="phoneNumber"
            maxLength="11"
            onChange={(e) => handleFormInputes("PHONE", e)}
            required
          />
          <label htmlFor="phoneNumber" className="input__label">
            Phone Number
          </label>
        </div>

        <div className="input__group field">
          <input
            type="input"
            className="input__field"
            placeholder="Email"
            name="email"
            id="email"
            onChange={(e) => handleFormInputes("EMAIL", e)}
            required
          />
          <label htmlFor="email" className="input__label">
            Email
          </label>
        </div>
      </div>

      <div className="cf__inputContainerFullWidth">
        <div className="input__group field">
          <textarea
            className="input__textArea"
            name=""
            id=""
            rows="5"
            placeholder="Your Message "
            onChange={(e) => handleFormInputes("MESSAGE", e)}
          ></textarea>

          <label htmlFor="howDidYouHearAboutUs" className="input__label">
            Your Message
          </label>
        </div>
      </div>

      <div className="cfp__fileInput">
        <label htmlFor="fileUpload" className="cfp__label">
          <span>Upload File</span>
        </label>
        <input
          type="file"
          id="fileUpload"
          className="cf__fileInput"
          onChange={(e) => handleFile(e)}
        />
      </div>

      <div className="cf__check">
        <input
          type="checkbox"
          name="contactType"
          id="contactType1"
          value="General Enquiry"
          // defaultChecked
          onChange={(e) => handleCheckbox(e)}
        />
        <label htmlFor="contactType1">
          I consent to receiving emails from CJS Builders. Read our Privacy
          Policy
        </label>
      </div>

      {error && <div className="messageText error">{error}</div>}

      {successMessage && (
        <div className="messageText success">{successMessage}</div>
      )}

      <div className="cf__btn" type="submit">
        {loading ? (
          <ButtonType2 btnName="Submitting..." />
        ) : (
          <ButtonType2 btnName="Submit" />
        )}
      </div>
    </form>
  );
};

export default GeneralInqueryForm;
