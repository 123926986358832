import React from "react";
import "./stickyBtnSkleton.scss";

const StickyBtnSkleton = ({ btnCount }) => {
  return (
    <div className="stickyBtnContainer">
      {Array.from({ length: btnCount }, (_, i) => (
        <div key={i} className="skeletonBtn skeleton"></div>
      ))}
    </div>
  );
};

export default StickyBtnSkleton;
