import React, { useEffect, useState } from "react";
import "./mainHeroUpdated.scss";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";

// static data
import ProgressiveImage from "../../need/progressiveImage/ProgressiveImage";

// API Services
import { getLandingCarousel } from "../../../apis/landing.apis";
import SkeletonMainHero from "../../skeletons/skeletonMainHero/SkeletonMainHero";

const MainHeroUpdated = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex);
  };

  const [loading, setLoading] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  useEffect(() => {
    setLoading(true);
    getLandingCarousel()
      .then((res) => {
        setCarouselData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  // console.log(carouselData);

  return (
    <div className="mainHeroUpdated">
      {loading ? (
        <SkeletonMainHero />
      ) : (
        <Swiper
          className="mainHeroUpdated__carousel"
          grabCursor={true}
          effect={"fade"}
          pagination={{
            dynamicBullets: false,
            clickable: true,
          }}
          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, EffectFade]}
          loop={true}
          speed={1000}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
        >
          {carouselData.map((item, index) => (
            <SwiperSlide className="mainHeroUpdated__carouselSlide" key={index}>
              <div className="mainHeroUpdated__carouselItems">
                <div className="mainHeroUpdatedCarousel__image">
                  <ProgressiveImage
                    src={item?.SLIDER_IMAGE}
                    placeholder={item?.SLIDER_IMAGE}
                    alt={item?.IMAGE_TEXT}
                    imgOpacity={0.4}
                  />
                </div>

                <div
                  className={
                    currentSlide === index
                      ? "mainHeroUpdatedCarousel__content mhuc__animated"
                      : "mainHeroUpdatedCarousel__content"
                  }
                >
                  <div className="mhu__company">
                    <div className="mhu__line"></div>
                    <span>{item?.IMAGE_TEXT}</span>
                  </div>

                  <h3 className="mhu__headerText">{item?.title}</h3>

                  <span className="mhu__subText">{item?.description}</span>

                  {/* <button>More</button> */}
                </div>

                <div className="s__count">
                  <span>
                    {/* {currentSlide + 1}/{mainHeroData.length} */} 0{" "}
                    {currentSlide + 1}
                  </span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default MainHeroUpdated;
