import React from "react";
import "./projectCard.scss";
import { Link } from "react-router-dom";

// components
import ButtonType2 from "../../buttons/buttonType2/ButtonType2";
import ProgressiveImage from "../../need/progressiveImage/ProgressiveImage";

const ProjectCard = ({ data }) => {
  console.log("ooppppppppppppp", data);
  return (
    <Link to={`/project/${data?.SLAG}`} className="link">
      <div className="project__card">
        <div className="project__cardImgBx">
          {/* <img src={data?.PROJECT_IMAGE} alt={data?.title} /> */}
          <ProgressiveImage
            src={data?.PROJECT_IMAGE}
            placeholder={data?.PROJECT_IMAGE}
            alt={data?.PROJECT_NAME}
          />
        </div>

        <div className="project__cardContentBx">
          <h2>{data?.PROJECT_NAME}</h2>

          <div className="size">
            {/* <span>Residential</span>
            <span>Renovation</span>
            <span>Interior</span> */}
            {data?.projectSector &&
              data?.projectSector.map((item, i) => <span key={i}>{item}</span>)}
          </div>

          {/* <div className="color">
          <h3>Color :</h3>
          <span>asdjkhskjh</span>
        </div> */}

          {/* <a href="/project-details">View Details</a> */}

          <div className="projectCardBtn">
            <ButtonType2 btnName={"View Details"} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
