import React, { useEffect, useState } from "react";
import { startTransition } from "react";
import "./servicePage.scss";
import { Link, useParams } from "react-router-dom";

// components
import ServiceCard from "../../../components/cards/serviceCard/ServiceCard";
import SubHero from "../../../components/heroSections/subHero/SubHero";
import LayoutAnimations from "../../../components/amimation/framerAnimations/LayoutAnimations";
import StickyBtnSkleton from "../../../components/skeletons/button/stickyBtnSkleton/StickyBtnSkleton";
import ServiceCardSkeleton from "../../../components/skeletons/cards/serviceCardSkeleton/ServiceCardSkeleton";

// data imports
import Navbar from "../../../components/common/navbar/Navbar";
import Footer from "../../../components/common/footer/Footer";

// api services
import {
  getServiceMainCategory,
  getServiceList,
} from "../../../apis/service.apis";

const ServicePage = () => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);

  const [activeService, setActiveService] = useState(
    slug ? slug : localStorage.getItem("activeService") || "general-contracting"
  );
  useEffect(() => {
    localStorage.setItem("activeService", activeService);
  }, [activeService]);

  // dynamic data
  const [mainCategoryData, setMainCategoryData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [filteredServiceData, setFilteredServiceData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getServiceList()
      .then((response) => {
        setServiceData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    getServiceMainCategory()
      .then((response) => {
        setMainCategoryData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDynamicServiceChange = (service) => {
    startTransition(() => {
      setActiveService(service);
      window.history.pushState(null, null, `/services/${service}`); // this line is added to change the url without refreshing the page
    });
  };

  useEffect(() => {
    if (activeService === "general-contracting") {
      setFilteredServiceData(serviceData[0]);
    } else {
      const filteredData = serviceData.filter(
        (service) => service.SLAG === activeService
      );
      setFilteredServiceData(filteredData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeService, serviceData]);
  // console.log(filteredServiceData);

  return (
    <div className="servicePage">
      <Navbar />

      <SubHero
        banner={
          "https://images.pexels.com/photos/11812135/pexels-photo-11812135.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        }
        title={"Our Services"}
      />

      {/* dynamic */}
      <div className="a2__container__btns">
        {loading ? (
          <StickyBtnSkleton btnCount={5} />
        ) : (
          mainCategoryData.map((item) => (
            <div
              key={item.SLAG}
              className={`a2B ${activeService === item.SLAG ? "active" : ""}`}
              onClick={() => handleDynamicServiceChange(item.SLAG)}
            >
              {item.SERVICE_CATEGORY_NAME}
            </div>
          ))
        )}
      </div>

      <div className="services">
        <div className="servicesContent">
          <div className="service__container">
            {loading ? (
              <>
                <ServiceCardSkeleton />
                <ServiceCardSkeleton />
                <ServiceCardSkeleton />
                <ServiceCardSkeleton />
                <ServiceCardSkeleton />
                <ServiceCardSkeleton />
              </>
            ) : (
              filteredServiceData?.subcatList?.map((service, index) => (
                <Link
                  // to={`/services/${activeService}/${service.SERVICE_SUB_CATEGORY_ID}`}

                  to={`/services/${activeService}/${service.SLAG}`}
                  state={{ serviceData: service }}
                  key={index}
                  className="service__containerLink"
                >
                  {/* <SingleServiceCard data={service} /> */}
                  <LayoutAnimations
                    propVarient={"scaleUp"}
                    delaySec={index}
                    key={service.id}
                  >
                    <ServiceCard data={service} />
                  </LayoutAnimations>
                </Link>
              ))
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ServicePage;
