import React from "react";
import "./skeletonLendingService.scss";

const SkeletonLendingService = () => {
  return (
    <div className="skeletonLendingServices">
      <div className="skeleton sls__item">
        <div className="skeleton sls__itemTitle"></div>
      </div>
      <div className="skeleton sls__item">
        <div className="skeleton sls__itemTitle"></div>
      </div>
      <div className="skeleton sls__item">
        <div className="skeleton sls__itemTitle"></div>
      </div>
      <div className="skeleton sls__item">
        <div className="skeleton sls__itemTitle"></div>
      </div>
      <div className="skeleton sls__item">
        <div className="skeleton sls__itemTitle"></div>
      </div>
      <div className="skeleton sls__item">
        <div className="skeleton sls__itemTitle"></div>
      </div>
    </div>
  );
};

export default SkeletonLendingService;
