import React from "react";
import "./careerPageSkeleton.scss";

const CareerPageSkeleton = () => {
  return (
    <div className="careerPageSkeleton">
      <div className="cps__skeletonContent">
        <div className="skeleton cps__skeletonHeader"></div>

        <div className="cps__skeletonRowContainer">
          <div className="skeleton cps__skeletonRowItem"></div>

          <div className="skeleton cps__skeletonRowItem"></div>

          <div className="skeleton cps__skeletonRowItem"></div>
        </div>
      </div>

      <div className="cps__skeletonContent">
        <div className="skeleton cps__skeletonHeader"></div>

        <div className="cps__skeletonRowContainer">
          <div className="skeleton cps__skeletonRowItem"></div>

          <div className="skeleton cps__skeletonRowItem"></div>
        </div>
      </div>
    </div>
  );
};

export default CareerPageSkeleton;
