import React from "react";
import "./sectionHeader.scss";
import ButtonType2 from "../../buttons/buttonType2/ButtonType2";
import { Link } from "react-router-dom";

const SectionHeader = ({ title, btnName, btnName2, link }) => {
  return (
    <div className="sectionHeader">
      <h3 className="sectionHeader__text">{title}</h3>

      {btnName2 ? (
        <Link to={link} className="sectionHeader__link">
          <span>{btnName2}</span>
        </Link>
      ) : (
        <Link to={link} className="sectionHeader__link">
          <ButtonType2 btnName={btnName} link={link} />
        </Link>
      )}
    </div>
  );
};

export default SectionHeader;
