import React from "react";
import "./ourMission.scss";
import parse from "html-react-parser";

// img
import SkeletonAboutMission from "../../../../components/skeletons/pageSkeletons/aboutPageSkeletons/SkeletonAboutMission";

const OurMission = ({ data, loading }) => {
  console.log(loading);

  return (
    <>
      {loading ? (
        <SkeletonAboutMission />
      ) : (
        <div className="apm__wrapper">
          <div className="aboutPage__missionContainer">
            <div className="missionContainer__left">
              <img src={data?.IMAGE} loading="lazy" alt="" />
            </div>

            <div className="missionContainer__right">
              <h3 className="cSectionText">{data?.BANNER_TITLE}</h3>

              {data?.DESCRIPTION && parse(data?.DESCRIPTION)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OurMission;
