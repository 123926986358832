import React, { useEffect, useState } from "react";
import "./contactForms.scss";

// Api services
import { applySubConstructor } from "../../../apis/contact.apis";

// components
import ButtonType2 from "../../../components/buttons/buttonType2/ButtonType2";
import { useNavigate } from "react-router-dom";

const SubConstructorForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sucessMessage, setSucessMessage] = useState(null);
  const [addServiceError, setAddServiceError] = useState(null);

  const [formInputs, setFormInputs] = useState({
    CONTRACT_TYPE: "sub-contractor",
    FULLNAME: "",
    PHONE: "",
    EMAIL: "",
    MESSAGE: "",
    COMPANY_POSITION: "",
    COMPANY_NAME: "",
    COMPANY_WEBSITE: "",
    AREA_OF_BUSINESS: "",
    serviceList: [],
  });
  const handleFormInputes = (name, e) => {
    setFormInputs({ ...formInputs, [name]: e.target.value });
  };

  const [addServiceInputs, setAddServiceInputs] = useState({
    SERVICE_NAME: "",
    SERVICE_DESCRIPTION: "",
  });
  const handleAddServiceInputs = (name, e) => {
    setAddServiceInputs({ ...addServiceInputs, [name]: e.target.value });
  };

  const handleAddService = (SERVICE_NAME, SERVICE_DESCRIPTION) => {
    if (SERVICE_NAME === "" || SERVICE_DESCRIPTION === "") {
      setAddServiceError("Please add a item name and description");
      return;
    } else {
      setFormInputs({
        ...formInputs,
        serviceList: [
          ...formInputs.serviceList,
          { SERVICE_NAME, SERVICE_DESCRIPTION },
        ],
      });
      setAddServiceInputs({ SERVICE_NAME: "", SERVICE_DESCRIPTION: "" });
      setAddServiceError(null);
    }
  };

  const formValidation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{11}$/;

    if (formInputs.FULLNAME < 3) {
      setError("Full Name must be at least 3 characters");
      return false;
    } else if (!phoneRegex.test(formInputs.PHONE || formInputs.PHONE > 11)) {
      setError("Invalid or Phone number must be at least 11 characters");
      return false;
    } else if (!emailRegex.test(formInputs.EMAIL)) {
      setError("Invalid email address");
      return false;
    } else if (formInputs.COMPANY_NAME < 3) {
      setError("Company Name must be at least 3 characters");
      return false;
    } else {
      return true;
    }
  };

  const [preQualificationFile, setPreQualificationFile] = useState(null);
  const [companyProfileFile, setCompanyProfileFile] = useState(null);
  const [serviceListFile, setServiceListFile] = useState(null);
  const handleFileUpload = (e, type) => {
    const file = e.target.files[0];

    const validFileTypes = ["application/pdf", "application/msword"];
    if (!validFileTypes.includes(file.type)) {
      setError("Invalid file type, please upload a PDF or Word document");
      return;
    }

    if (type === "preQualification") {
      setPreQualificationFile(file);
    }
    if (type === "companyProfile") {
      setCompanyProfileFile(file);
    }
    if (type === "serviceList") {
      setServiceListFile(file);
    }

    setError(null);
  };

  const [checkBox, setCheckBox] = useState(false);
  const handleCheckBox = (e) => {
    setCheckBox(e.target.checked);
  };

  const handleSubmition = (e) => {
    e.preventDefault();

    setLoading(true);

    if (!formValidation()) {
      setLoading(false);
      return;
    }

    if (!checkBox) {
      setError("Please agree to our terms and conditions");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("CONTRACT_TYPE", formInputs.CONTRACT_TYPE);
    formData.append("FULLNAME", formInputs.FULLNAME);
    formData.append("PHONE", formInputs.PHONE);
    formData.append("EMAIL", formInputs.EMAIL);
    formData.append("MESSAGE", formInputs.MESSAGE);
    formData.append("COMPANY_POSITION", formInputs.COMPANY_POSITION);
    formData.append("COMPANY_NAME", formInputs.COMPANY_NAME);
    formData.append("COMPANY_WEBSITE", formInputs.COMPANY_WEBSITE);
    formData.append("AREA_OF_BUSINESS", formInputs.AREA_OF_BUSINESS);
    formInputs.serviceList.forEach((service, index) => {
      formData.append(
        `serviceList[${index}][SERVICE_NAME]`,
        service.SERVICE_NAME
      );
      formData.append(
        `serviceList[${index}][SERVICE_DESCRIPTION]`,
        service.SERVICE_DESCRIPTION
      );
    });

    if (preQualificationFile) {
      formData.append("Pre_QualificationFile", preQualificationFile);
    }
    if (companyProfileFile) {
      formData.append("Company_Profile", companyProfileFile);
    }
    if (serviceListFile) {
      formData.append("Service_List_File", serviceListFile);
    }

    // print form data to console
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    applySubConstructor(formData)
      .then((res) => {
        // console.log(res);

        setSucessMessage("Application submitted successfully");
        setError(null);
        setAddServiceError(null);
        setLoading(false);
        setFormInputs({
          CONTRACT_TYPE: "sub-contractor",
          FULLNAME: "",
          PHONE: "",
          EMAIL: "",
          MESSAGE: "",
          COMPANY_POSITION: "",
          COMPANY_NAME: "",
          COMPANY_WEBSITE: "",
          AREA_OF_BUSINESS: "",
          serviceList: [],
        });

        setTimeout(() => {
          navigate("/confirmation");
        }, 2000);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        setError("Something went wrong, please try again later");
      });
  };

  return (
    <form className="c__form" action="" onSubmit={handleSubmition}>
      {/* <h3>Apply for Sub-Constructor</h3> */}
      <div className="cf__inputSection">
        <h3>About You</h3>

        <div className="cf__inputContainer">
          <div className="input__group field">
            <input
              type="input"
              className="input__field"
              placeholder="Full Name"
              name="FULLNAME"
              id="FULLNAME"
              value={formInputs.FULLNAME}
              onChange={(e) => handleFormInputes("FULLNAME", e)}
              required
            />
            <label htmlFor="name" className="input__label">
              Full Name
            </label>
          </div>

          <div className="input__group field">
            <input
              type="input"
              className="input__field"
              placeholder="Company Position"
              name="COMPANY_POSITION"
              id="COMPANY_POSITION"
              value={formInputs.COMPANY_POSITION}
              onChange={(e) => handleFormInputes("COMPANY_POSITION", e)}
              required
            />
            <label htmlFor="COMPANY_POSITION" className="input__label">
              Company Position
            </label>
          </div>

          <div className="input__group field">
            <input
              type="number"
              className="input__field"
              placeholder="Phone Number"
              name="PHONE"
              id="PHONE"
              maxLength="11"
              value={formInputs.PHONE}
              onChange={(e) => handleFormInputes("PHONE", e)}
              required
            />
            <label htmlFor="phoneNumber" className="input__label">
              Phone Number
            </label>
          </div>

          <div className="input__group field">
            <input
              type="input"
              className="input__field"
              placeholder="Email"
              name="EMAIL"
              id="EMAIL"
              value={formInputs.EMAIL}
              onChange={(e) => handleFormInputes("EMAIL", e)}
              required
            />
            <label htmlFor="email" className="input__label">
              Email
            </label>
          </div>
        </div>
      </div>

      <div className="cf__inputSection">
        <h3>About Your Company</h3>
        <div className="cf__inputContainer">
          <div className="input__group field">
            <input
              type="input"
              className="input__field"
              placeholder="Company Name"
              name="COMPANY_NAME"
              id="COMPANY_NAME"
              value={formInputs.COMPANY_NAME}
              onChange={(e) => handleFormInputes("COMPANY_NAME", e)}
              required
            />
            <label htmlFor="companyName" className="input__label">
              Company Name
            </label>
          </div>

          <div className="input__group field">
            <input
              type="text"
              className="input__field"
              placeholder="Company Website"
              name="COMPANY_WEBSITE"
              id="COMPANY_WEBSITE"
              value={formInputs.COMPANY_WEBSITE}
              onChange={(e) => handleFormInputes("COMPANY_WEBSITE", e)}
              // required
            />
            <label htmlFor="COMPANY_WEBSITE" className="input__label">
              Company Website
            </label>
          </div>
        </div>

        <div className="cf__inputContainerFullWidth">
          <div className="input__group field">
            <input
              type="input"
              className="input__field"
              placeholder="Area of Business"
              name="AREA_OF_BUSINESS"
              id="AREA_OF_BUSINESS"
              value={formInputs.AREA_OF_BUSINESS}
              onChange={(e) => handleFormInputes("AREA_OF_BUSINESS", e)}
              // required
            />
            <label htmlFor="AREA_OF_BUSINESS" className="input__label">
              Area of Business
            </label>
          </div>
        </div>
      </div>

      <div className="cf__inputSection">
        <h3>Add Services</h3>

        <div className="cf__inputContainer">
          <div className="input__group field">
            <input
              type="input"
              className="input__field"
              placeholder="Service Name"
              name="SERVICE_NAME"
              id="SERVICE_NAME"
              value={addServiceInputs.SERVICE_NAME}
              onChange={(e) => handleAddServiceInputs("SERVICE_NAME", e)}
            />
            <label htmlFor="SERVICE_NAME" className="input__label">
              Service Name
            </label>
          </div>

          <div className="input__group field">
            <input
              type="input"
              className="input__field"
              placeholder="Service Description"
              name="SERVICE_DESCRIPTION"
              id="SERVICE_DESCRIPTION"
              value={addServiceInputs.SERVICE_DESCRIPTION}
              onChange={(e) => handleAddServiceInputs("SERVICE_DESCRIPTION", e)}
            />
            <label htmlFor="SERVICE_DESCRIPTION" className="input__label">
              Service Description
            </label>
          </div>

          <span
            className="cf__btn"
            onClick={() =>
              handleAddService(
                addServiceInputs.SERVICE_NAME,
                addServiceInputs.SERVICE_DESCRIPTION
              )
            }
          >
            {addServiceInputs.SERVICE_NAME &&
            addServiceInputs.SERVICE_DESCRIPTION
              ? "Add Service"
              : "Add Another Service"}
          </span>
        </div>

        {addServiceError && (
          <div className="messageText error">{addServiceError}</div>
        )}

        {formInputs.serviceList.length >= 1 ? (
          <div className="cf__serviceList">
            {formInputs.serviceList.map((service, index) => (
              <div className="cf__serviceItem" key={index}>
                <span>{service.SERVICE_NAME}</span>
                <span>{service.SERVICE_DESCRIPTION}</span>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <div className="cf__inputContainerFullWidth">
        <div className="input__group field">
          <textarea
            className="input__textArea"
            name=""
            id=""
            rows="5"
            placeholder="Your Message "
            onChange={(e) => handleFormInputes("MESSAGE", e)}
          ></textarea>

          <label htmlFor="howDidYouHearAboutUs" className="input__label">
            Your Message
          </label>
        </div>
      </div>

      <div className="fileInputContainer">
        <div className="cfp__fileInput">
          <label htmlFor="fileUpload" className="cfp__label">
            <span>Pre-Qualification</span>
          </label>
          <input
            type="file"
            id="fileUpload"
            className="cf__fileInput"
            accept=".pdf, .doc, .docx"
            onChange={(e) => handleFileUpload(e, "preQualification")}
          />
        </div>

        <div className="cfp__fileInput">
          <label htmlFor="fileUpload" className="cfp__label">
            <span>Company Profile</span>
          </label>
          <input
            type="file"
            id="fileUpload"
            className="cf__fileInput"
            accept=".pdf, .doc, .docx"
            onChange={(e) => handleFileUpload(e, "companyProfile")}
          />
        </div>

        <div className="cfp__fileInput">
          <label htmlFor="fileUpload" className="cfp__label">
            <span>Service List</span>
          </label>
          <input
            type="file"
            id="fileUpload"
            className="cf__fileInput"
            accept=".pdf, .doc, .docx"
            onChange={(e) => handleFileUpload(e, "serviceList")}
          />
        </div>
      </div>

      <div className="cf__check">
        <input
          type="checkbox"
          name="contactType"
          id="contactType1"
          value="General Enquiry"
          onChange={(e) => handleCheckBox(e)}
          // defaultChecked
        />
        <label htmlFor="contactType1">
          I consent to receiving emails from CJS Builders. Read our Privacy
          Policy.
        </label>
      </div>

      {error && <div className="messageText error">{error}</div>}

      {sucessMessage && (
        <div className="messageText success">{sucessMessage}</div>
      )}

      <div className="cf__btn" type="submit">
        {loading ? (
          <ButtonType2 btnName="Loading..." />
        ) : (
          <ButtonType2 btnName="Submit" />
        )}
      </div>
    </form>
  );
};

export default SubConstructorForm;
