import React from "react";
import "../customerPolicy/customerPolicy.scss";
import parse from "html-react-parser";

// components

const EnvironmentalPolicy = ({ data, loading }) => {
  return (
    <div className="customerPolicy">
      <h3 className="customerPolicy__headerText">{data.TITLE}</h3>

      <div className="customerPolicy__content">
        {data.DESCRIPTION && parse(data.DESCRIPTION)}

        {/* <span className="customerPolicy__contentDescText">
          Dedicated to sustainable practices, SEMCORP Limited promises to
          incorporate responsible environmental policies into all aspects of our
          business operations. We acknowledge the critical role we play in
          protecting the environment.
        </span>

        <div className="customerPolicy__contentList">
          <ul className="customerPolicy__contentListItems">
            <li className="customerPolicy__contentListItem">
              Protecting the environment and preventing pollution.
            </li>

            <li className="customerPolicy__contentListItem">
              Adhering to all necessary environmental legislation and
              requirements.
            </li>

            <li className="customerPolicy__contentListItem">
              Goals include reducing waste, promoting recycling, saving energy
              and material resources, and lowering hazardous emissions.
            </li>

            <li className="customerPolicy__contentListItem">
              Working with suppliers to encourage positive environmental
              practices.
            </li>

            <li className="customerPolicy__contentListItem">
              We are always improving our environmental performance by
              establishing goals, activities, and targets.
            </li>

            <li className="customerPolicy__contentListItem">
              Communicating our policy both internally and to the public.
            </li>
          </ul>
        </div>

        <span className="customerPolicy__contentDescText">
          SEMCORP is dedicated to environmental stewardship and leads by
          example, helping to create a more environmentally friendly future for
          future generations.
        </span> */}
      </div>
    </div>
  );
};

export default EnvironmentalPolicy;
