import React, { useEffect, useState } from "react";
import "./aboutPage2.scss";

// components
import SubHero from "../../components/heroSections/subHero/SubHero";
import Navbar from "../../components/common/navbar/Navbar";
import Footer from "../../components/common/footer/Footer";
import OurMission from "./components/ourMission/OurMission";
import OurVission from "./components/ourVission/OurVission";
// import BusinessValue from "./components/businessValue/BusinessValue";
import OurPeople from "./components/ourPeople/OurPeople";
// import OurStrength from "./components/ourStrength/OurStrength";
import CompanyPolicies from "./components/companyPolicies/CompanyPolicies";

// API SERVICE
import { getAbout } from "../../apis/about.apis";
import StickyBtnSkleton from "../../components/skeletons/button/stickyBtnSkleton/StickyBtnSkleton";
import SkeletonAboutMission from "../../components/skeletons/pageSkeletons/aboutPageSkeletons/SkeletonAboutMission";
import SkeletonAboutVission from "../../components/skeletons/pageSkeletons/aboutPageSkeletons/SkeletonAboutVission";
import SkeletonAboutPeople from "../../components/skeletons/pageSkeletons/aboutPageSkeletons/SkeletonAboutPeople";
import SkeletonAboutPolicis from "../../components/skeletons/pageSkeletons/aboutPageSkeletons/SkeletonAboutPolicis";

const AboutPage2 = () => {
  const [aboutData, setAboutData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAbout()
      .then((res) => {
        setAboutData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  // console.log(aboutData);
  // console.log(aboutData[0]?.BANNER_TITLE);

  const btns = [
    aboutData?.map((data) => {
      return data?.BANNER_TITLE;
    }),
  ];
  // console.log(btns[0].BANNER_TITLE);

  // const [activeBtn, setActiveBtn] = useState("#mission");
  const [activeBtn, setActiveBtn] = useState(`#${btns[0][0]}`); // `#${btns[0].BANNER_TITLE}
  // console.log(activeBtn);

  // const btnData = [
  //   { id: 1, name: "Mission", path: "#mission" },
  //   { id: 2, name: "Vision", path: "#vision" },
  //   { id: 3, name: "Our People", path: "#ourPeople" },
  //   // { id: 4, name: "Our Strength", path: "#ourStrength" },
  //   { id: 5, name: "Policies", path: "#policies" },
  //   // { id: 6, name: "Business Values", path: "#businessValues" },
  // ];

  window.onscroll = () => {
    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      const top = section.offsetTop - 220;
      const height = section.offsetHeight;
      if (window.scrollY >= top && window.scrollY < top + height) {
        setActiveBtn(`#${section.id}`);
      }
    });
  };

  return (
    <div className="a2">
      <Navbar />

      <SubHero
        banner={
          "https://images.pexels.com/photos/16047352/pexels-photo-16047352/free-photo-of-man-welding-pipe.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        }
        // title="About Us"
        desc="We are a developing general contractor and building maintenance company. We offer solutions for new construction, additions & alterations, and facility maintenance."
      />

      {/* <div className="a2__container__btns">
        {btnData.map((btn) => (
          <a
            href={btn.path}
            key={btn.id}
            className={activeBtn === btn.path ? "a2B active" : "a2B"}
            onClick={() => setActiveBtn(btn.path)}
          >
            {btn.name}
          </a>
        ))}
      </div> */}

      <div className="a2__container__btns">
        {loading ? (
          <StickyBtnSkleton btnCount={4} />
        ) : (
          <>
            {btns[0].map((btn) => (
              <a
                href={`#${btn}`}
                key={btn.id}
                className={activeBtn === `#${btn}` ? "a2B active" : "a2B"}
                onClick={() => setActiveBtn(btn)}
              >
                {btn}
              </a>
            ))}
          </>
        )}
      </div>

      <div className="a2__container">
        <div className="a2__container__content">
          {loading ? (
            <>
              <SkeletonAboutMission />
              <SkeletonAboutVission />
              <SkeletonAboutPeople />
              <SkeletonAboutPolicis />
            </>
          ) : (
            <>
              <section id={btns[0][0]}>
                {aboutData[0] && (
                  <OurMission data={aboutData[0]} loading={loading} />
                )}
              </section>

              <section id={btns[0][1]}>
                {aboutData[1] && (
                  <OurVission data={aboutData[1]} loading={loading} />
                )}
              </section>

              <section id={btns[0][2]}>
                {aboutData[2] && (
                  <OurPeople data={aboutData[2]} loading={loading} />
                )}
              </section>

              <section id={btns[0][3]}>
                {aboutData[3] && (
                  <CompanyPolicies data={aboutData[3]} loading={loading} />
                )}
              </section>
            </>
          )}

          {/* <section id="ourStrength">
            <OurStrength />
          </section> */}

          {/* <section id="businessValues">
            <BusinessValue />
          </section> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage2;
