import React, { useState } from "react";
import "./contactPage.scss";

// components
import AppLayout from "../../components/layouts/appLayout/AppLayout";
import Wrapper from "../../components/layouts/wrapper/Wrapper";
import SubHero from "../../components/heroSections/subHero/SubHero";
import ButtonType2 from "../../components/buttons/buttonType2/ButtonType2";
import SubConstructorForm from "./components/SubConstructorForm";
import EnlistedSupplier from "./components/EnlistedSupplier";
import GeneralInqueryForm from "./components/GeneralInqueryForm";

const ContactPage = () => {
  const [activeForm, setActiveForm] = useState("General Inquery");

  const formTypes = [
    {
      id: 1,
      name: "Apply for Sub-Constructor",
    },
    {
      id: 2,
      name: "General Inquery",
    },
    {
      id: 3,
      name: "Request for Enlisted Supplier",
    },
  ];

  const handleSubmition = () => {
    // console.log("Form Submitted");

    setTimeout(() => {
      window.location.href = "/confirmation";
    }, 100);
  };

  return (
    <AppLayout>
      <div className="pageLayout1">
        <SubHero
          title="Contact Us"
          bg={true}
          banner={
            "https://images.pexels.com/photos/21014/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          }
        />

        <Wrapper>
          <div className="contactPage">
            <div className="contactContainer">
              <div className="companyMap">
                <iframe
                  title="companyMap"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d548.6217649700934!2d90.41539317806465!3d23.749847140676636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9822da02e3b%3A0xb6f3e7cda4404d76!2sSEMCORP%20Limited!5e1!3m2!1sen!2sbd!4v1701841605268!5m2!1sen!2sbd"
                  width="100%"
                  height="100%"
                  // style="border:0;"
                  // allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>

              <div className="contsctInfos">
                <div className="contactInfo">
                  <h3 className="contactInfo__header">Office Address</h3>
                  <p className="contactInfo__desc">
                    SEMCORP Limited <br />
                    1005 Malibagh Chowdhury Para, 1st Floor, <br />
                    Dhaka 1219, Bangladesh
                  </p>
                </div>

                <div className="contactInfo">
                  <h3 className="contactInfo__header">Contact Info</h3>
                  <p className="contactInfo__desc">
                    Phone: +880 014-00409270 <br />
                    Email:{" "}
                    <a
                      href="mailto:inquiry@semcorp.ai"
                      className="contactInfo__email"
                    >
                      inquiry@semcorp.ai
                    </a>
                  </p>
                </div>

                <div className="contactInfo">
                  <h3 className="contactInfo__header">Working Hours</h3>
                  <p className="contactInfo__desc">
                    Saturday - Thursday: 9:00 AM - 5:00 PM <br />
                    Friday: Closed
                  </p>
                </div>
              </div>

              {/* <div className="companyMap">
                <iframe
                  title="companyMap"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d548.6217649700934!2d90.41539317806465!3d23.749847140676636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9822da02e3b%3A0xb6f3e7cda4404d76!2sSEMCORP%20Limited!5e1!3m2!1sen!2sbd!4v1701841605268!5m2!1sen!2sbd"
                  width="100%"
                  height="100%"
                  // style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div> */}
            </div>

            <div className="contactForm__container">
              <div className="cf__headerDesc">
                <span>Contact Us</span>
                <h2>Get in Touch</h2>
              </div>

              <div className="cf__btns">
                {formTypes.map((formType, index) => (
                  <div
                    className={`cf__btn ${
                      activeForm === formType.name ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => setActiveForm(formType.name)}
                  >
                    {formType.name}
                  </div>
                ))}
              </div>

              {activeForm === "Apply for Sub-Constructor" ? (
                <SubConstructorForm />
              ) : activeForm === "General Inquery" ? (
                <GeneralInqueryForm />
              ) : activeForm === "Request for Enlisted Supplier" ? (
                <EnlistedSupplier />
              ) : null}
            </div>
          </div>
        </Wrapper>

        {/* <ContactForm /> */}
      </div>
    </AppLayout>
  );
};

export default ContactPage;
