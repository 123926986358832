import React, { useEffect, useState } from "react";
import "./serviceListPage.scss";
import { useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";

// components
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import SubHero from "../../../components/heroSections/subHero/SubHero";
import Wrapper from "../../../components/layouts/wrapper/Wrapper";
import LineAnimation from "../../../components/amimation/lineAnimation/LineAnimation";

// API SERVICES
import { getSubServiceList } from "../../../apis/service.apis";
import SkeletonServiceListPage from "../../../components/skeletons/skeletonServiceListPage/SkeletonServiceListPage";

const ServiceListPage = () => {
  const location = useLocation();
  const serviceData = location.state.serviceData;
  // console.log(serviceData);

  const { subCatSlag } = useParams();
  const { catSlag } = useParams();

  const [loading, setLoading] = useState(false);
  const [subServiceData, setSubServiceData] = useState([]);
  useEffect(() => {
    setLoading(true);
    getSubServiceList(catSlag, subCatSlag)
      .then((res) => {
        setSubServiceData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [serviceData?.slug, subCatSlag, catSlag]);
  // console.log(subServiceData);

  return (
    <AppLayout>
      <div className="serviceListPage pageLayout1">
        <SubHero
          banner={subServiceData?.IMAGE}
          title={subServiceData?.SERVICE_SUB_CATEGORY_NAME}
        />

        <Wrapper>
          {loading ? (
            <SkeletonServiceListPage />
          ) : (
            <div className="serviceListPage__container">
              <div className="serviceListPage__header">
                <h3>{subServiceData?.SERVICE_SUB_CATEGORY_NAME}</h3>
                <LineAnimation width={"80px"} />
              </div>

              {subServiceData?.DESCRIPTION && (
                <div className="servicePage__desc">
                  {/* {subServiceData?.DESCRIPTION.map((item, index) => (
                  <p key={index}>{item?.item}</p>
                ))} */}

                  {parse(subServiceData?.DESCRIPTION)}
                </div>
              )}

              <div className="servicesList">
                {subServiceData?.servicelist?.map((service) => (
                  <div className="servicesItem" key={service.id}>
                    <img src={service?.SERVICE_IMAGE} alt="" loading="lazy" />

                    <div className="servicesItemInfo">
                      <span>{service?.SERVICE_NAME}</span>
                      {/* <LineAnimation width={"30px"} /> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default ServiceListPage;
