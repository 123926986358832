import React from "react";
import "./homePage.scss";

// components
import AppLayout from "../../components/layouts/appLayout/AppLayout";
import About from "../../components/pageComponents/about/About";
import MainHeroUpdated from "../../components/heroSections/mainHeroUpdated/MainHeroUpdated";
import LandingServices from "../../components/pageComponents/landingServices/LandingServices";
// import LandingProjects from "../../components/pageComponents/landingProjects/LandingProjects";
import ContactAdd from "../../components/pageComponents/contactAdd/ContactAdd";

import LandingProjects from "./components/landingProjects/LandingProjects";

// API Services

const HomePage = () => {
  return (
    <AppLayout bgColor="#1B1B1B">
      <div className="homePage homePageLayout">
        <MainHeroUpdated />

        <About />
        <LandingServices />
        <LandingProjects />
        <ContactAdd />
      </div>
    </AppLayout>
  );
};

export default HomePage;
