import React, { useEffect, useState } from "react";
import "./navbar.scss";
import { useLocation } from "react-router-dom";

// icon import
import { LuSearch } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
import { CgMenuGridO } from "react-icons/cg";

// image import
// import logo from "../../../assets/images/logos/logo_red_white.png";
// import logo from "../../../assets/images/logos/logo_red_darkGray.png";
// import logo from "../../../assets/images/logos/logo__svg.svg";
import logo from "../../../assets/images/updatedLogo/logo11.svg";

// data import
import navMenuData from "./navMenuData";
import SvgLogo from "./components/SvgLogo";

const Navbar = () => {
  const location = useLocation();
  // const [showSearch, setShowSearch] = useState(false);

  // const handleSearch = () => {
  //   setShowSearch(!showSearch);
  // };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.pageYOffset;
  //     const header = document.querySelector(".navbar");
  //     const navMenu = document.querySelector(".navbar__menuItem");

  //     if (currentScrollPos > 200) {
  //       header.style.backgroundColor = "#ffff";
  //       navMenu.style.color = "black";
  //     } else {
  //       header.style.backgroundColor = "transparent";
  //       navMenu.style.color = "white";
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <div className="navbar">
      <div className="navbarContainer">
        <div className="navbar__left">
          {/* <div className="navlogo" onClick={() => (window.location.href = "/")}>
            <img src={logo} alt="logo" />
          </div> */}

          <div className="navNaimatedLogo">
            <div
              className="navLogoIcons"
              onClick={() => (window.location.href = "/")}
            >
              <SvgLogo />
            </div>
          </div>
        </div>

        <div className="navbar__menu">
          {navMenuData.map((item, index) => (
            <a
              key={index}
              href={item.path}
              // onClick={() => (window.location.href = item.path)}
              className={
                location.pathname === item.path
                  ? "navbar__menuItem active__nav"
                  : "navbar__menuItem"
              }
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <span className="navbar__menuItemText">{item?.title}</span>
            </a>
          ))}

          <div className="navMobile__menuIcon">
            <div
              className="navbar__leftIcon"
              onClick={() =>
                document
                  .querySelector(".navMobile__menu")
                  .classList.add("active")
              }
            >
              <CgMenuGridO className="navbar__rightIcon" />
            </div>

            <div className="navMobile__menu">
              <div className="navMobile__menuContainer">
                <div className="navMobile__menuCloseIcon">
                  <IoMdClose
                    onClick={() =>
                      document
                        .querySelector(".navMobile__menu")
                        .classList.remove("active")
                    }
                  />
                </div>

                <div className="logo">
                  <div
                    className="logo"
                    onClick={() => (window.location.href = "/")}
                  >
                    <img src={logo} alt="" />
                  </div>
                </div>

                <span className="companyMoto"></span>

                {navMenuData.map((item, index) => (
                  <div
                    key={index}
                    className={
                      location.pathname === item.path
                        ? "navMobile__menuItem activeMobile__nav"
                        : "navMobile__menuItem"
                    }
                    onClick={() => (window.location.href = item.path)}
                  >
                    <span className="navMobile__menuItemText">
                      {item?.title}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="navbar__right">
          <div className="navbar__aboutContainer ">
            <div
              className="navbar__rightIcon"
              onClick={() =>
                document
                  .querySelector(".navbar__aboutMenu")
                  .classList.add("activeAbout")
              }
            >
              <CgMenuGridO />
            </div>

            <div className="navbar__aboutMenu">
              <div className="navbar__aboutMenuContainer">
                <div className="navbar__aboutMenuCloseIcon">
                  <IoMdClose
                    onClick={() =>
                      document
                        .querySelector(".navbar__aboutMenu")
                        .classList.remove("activeAbout")
                    }
                  />
                </div>

                <div className="logoMedium">
                  <img src={logo} alt="" />
                </div>

                <div className="navbarAbout__text">
                  <span>
                    SEMCORP Limited, where innovation meets excellence in the
                    realm of construction and retail services.
                  </span>
                </div>

                <div className="navbarAbout__contact">
                  <h3>Contact</h3>
                  <span>014-00409270</span>
                  <span>inquiry@semcorp.ai</span>
                  <span>www.semcorp.ai</span>
                </div>

                <div className="navbarAbout__address">
                  <h3>Office Address</h3>
                  <span>
                    1005 Malibagh Chowdhury Para, 1st Floor, Dhaka 1219
                  </span>
                </div>

                <div className="navbarAbout__socials">
                  <div className="navbarAbout__social">
                    <i className="fab fa-facebook-f"></i>
                  </div>

                  <div className="navbarAbout__social">
                    <i className="fab fa-twitter"></i>
                  </div>

                  <div className="navbarAbout__social">
                    <i className="fab fa-instagram"></i>
                  </div>

                  <div className="navbarAbout__social">
                    <i className="fab fa-linkedin-in"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="navbar__rightLine"></div>

          <LuSearch
            className={
              showSearch
                ? "navbar__rightIcon active__icon"
                : "navbar__rightIcon"
            }
            onClick={handleSearch}
          />

          {showSearch && (
            <div className="navbar__search">
              <div className="navbar__searchContainer">
                <div className="navbar__searchInput">
                  <input type="text" placeholder="Search..." />
                </div>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;
