import React, { useEffect, useState } from "react";
import "./projectListPage.scss";

// components
import Navbar from "../../../components/common/navbar/Navbar";
import Footer from "../../../components/common/footer/Footer";
import SubHero from "../../../components/heroSections/subHero/SubHero";
import ProjectCardSkeleton from "../../../components/skeletons/cards/projectCardSkeleton/ProjectCardSkeleton";
import ProjectCard from "../../../components/cards/projectCard/ProjectCard";

// img
import i from "../../../assets/images/carousel/slide3.jpg";

// API SERVICE
import { getAllProjects } from "../../../apis/projects.apis";
import StickyBtnSkleton from "../../../components/skeletons/button/stickyBtnSkleton/StickyBtnSkleton";

const ProjectListPage = () => {
  const [loading, setLoading] = useState(false);
  const [activeProjectType, setActiveProjectType] = useState("ALL");
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const handleProjectFilter = (category) => {
    setActiveProjectType(category);
  };

  useEffect(() => {
    setLoading(true);
    getAllProjects()
      .then((res) => {
        setProjects(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (activeProjectType === "ALL") {
      setFilteredProjects(projects);
    } else {
      const filteredData = projects.filter(
        (project) => project.REF_PROJECT_TYPE_NAME === activeProjectType
      );
      setFilteredProjects(filteredData);
    }
  }, [activeProjectType, projects]);
  // console.log(filteredProjects);

  return (
    <div className="projectListPage">
      <Navbar />

      <SubHero banner={i} title={"Project List"} />

      <div className="a2__container__btns">
        <div
          className={`a2B ${activeProjectType === "ALL" ? "active" : ""}`}
          onClick={() => handleProjectFilter("ALL")}
        >
          All
        </div>

        {loading ? (
          <>
            <div className="skeletonBtn skeleton"></div>
            <div className="skeletonBtn skeleton"></div>
          </>
        ) : (
          projects
            .map((item) => item.REF_PROJECT_TYPE_NAME)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((item) => (
              <div
                key={item}
                className={`a2B ${activeProjectType === item ? "active" : ""}`}
                onClick={() => handleProjectFilter(item)}
              >
                {item}
              </div>
            ))
        )}
      </div>

      <div className="projectListPage__container">
        <div className="projectListPage__content">
          <div className="pgp__projects">
            {loading ? (
              <>
                <ProjectCardSkeleton />
                <ProjectCardSkeleton />
                <ProjectCardSkeleton />
              </>
            ) : (
              filteredProjects.map((project) => (
                <ProjectCard key={project.PROJECT_ID} data={project} />
              ))
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProjectListPage;
