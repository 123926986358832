import React, { useEffect } from "react";
import "./app.scss";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";

import {
  HomePage,
  AboutPage,
  ServicePage,
  ServiceListPage,
  ContactPage,
  ProjectListPage,
  ProjectDetailsPage,
  CareerPage,
  CareerDetailsPage,
  CareerFormPage,
  ConfirmationPage,
  ErrorPage,
} from "./pages";

const App = () => {
  const location = useLocation();

  // when the location changes, scroll to the top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/about" element={<AboutPage />} />

        <Route path="/services" element={<Outlet />}>
          <Route path=":slug" element={<ServicePage />} />
          <Route path=":catSlag/:subCatSlag" element={<ServiceListPage />} />
        </Route>

        <Route path="/project" element={<Outlet />}>
          <Route path="" element={<ProjectListPage />} />
          <Route path=":projectSlug" element={<ProjectDetailsPage />} />
        </Route>

        <Route path="/career" element={<Outlet />}>
          <Route path="" element={<CareerPage />} />
          <Route path=":jobPostId" element={<CareerDetailsPage />} />
          <Route path=":slug/:slug/:slug" element={<CareerFormPage />} />
        </Route>

        <Route path="/contact" element={<ContactPage />} />

        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default App;
