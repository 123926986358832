import React from "react";
import "./projectCardSkeleton.scss";

const ProjectCardSkeleton = () => {
  return (
    <section className="projectCardSkeleton">
      <div className="skeleton projectCardSkeleton__img " />

      <div className="skeleton pcs__header" />
      <div className="skeleton pcs__tags" />

      <div className="skeleton pcs__tags" />
    </section>
  );
};

export default ProjectCardSkeleton;
