import React from "react";
import "./confirmationPage.scss";
import AppLayout from "../../components/layouts/appLayout/AppLayout";
import { Link } from "react-router-dom";

const ConfirmationPage = () => {
  return (
    <AppLayout>
      <div className="confirmationPage">
        <div className="confirmationPage__container">
          <h1>Thank you for your submission</h1>
          <span>
            We have received your application and will review it shortly. You
            will receive an email confirmation once your application has been
            reviewed.
          </span>

          <div className="confirmationPage__cta">
            <Link to="/" className="buttonType2">
              Go back to home
            </Link>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default ConfirmationPage;
