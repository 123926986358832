import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";

// icon imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faXTwitter,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

// component imports
import Wrapper from "../../layouts/wrapper/Wrapper";

// import logo from "../../../assets/images/logos/Logo123555.png";
import logo from "./img/footer__logo11.svg";

const Footer = () => {
  return (
    <div className="footer">
      <Wrapper>
        <div className="footerContainer">
          <div className="footerLogo">
            <img src={logo} alt="" />
          </div>

          <div className="footer__infoContainer">
            <div className="footerItemContainer">
              <h3 className="footerItemTitle">Corporate</h3>

              <Link to="/about" className="footerItem">
                About
              </Link>
              <Link to="/services/general-contracting" className="footerItem">
                Services
              </Link>
              <Link to="/project" className="footerItem">
                Projects
              </Link>

              <Link to="/career" className="footerItem">
                Careers
              </Link>

              <Link to="/contact" className="footerItem">
                Contact
              </Link>
            </div>

            <div className="footerItemContainer">
              <h3 className="footerItemTitle">Services</h3>

              <Link to="/" className="footerItem">
                General Contracting
              </Link>
              <Link to="/services/retrofitting" className="footerItem">
                Retrofitting
              </Link>
              <Link to="/services/mep" className="footerItem">
                MEP
              </Link>
              <Link
                to="/services/speciality-technical-work"
                className="footerItem"
              >
                Speciality Technical Work
              </Link>
              <Link to="/services/fit-out" className="footerItem">
                Fit Out
              </Link>
              <Link to="/services/turnkey" className="footerItem">
                Turnkey
              </Link>
            </div>
          </div>

          <div className="footerCompany">
            <div className="footerItemContainer">
              <h3 className="footerItemTitle">SEMCORP Limited</h3>
            </div>

            <div className="footerItemContainer">
              <span className="footerItem">
                1005 (1st Floor) Malibagh Chowdhurypara, Dhaka 1219, Bangladesh.
              </span>
            </div>

            <div className="footerItemContainer">
              <span className="footerItem">
                +88 014 00 40 9272 <br /> inquiry@semcorp.ai
              </span>
            </div>

            <div className="footer__socials">
              <a
                href="https://www.facebook.com/semcorplimited"
                target="_blank"
                rel="noreferrer"
                className="footer__social"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  style={{ color: "#ffffff" }}
                />
              </a>

              <a
                href="https://twitter.com/semcorp_limited"
                target="_blank"
                rel="noreferrer"
                className="footer__social"
              >
                <FontAwesomeIcon
                  icon={faXTwitter}
                  style={{ color: "#ffffff" }}
                />
              </a>

              <a
                href="https://www.linkedin.com/company/semcorp-limited"
                target="_blank"
                rel="noreferrer"
                className="footer__social"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  style={{ color: "#ffffff" }}
                />
              </a>
              <a
                href="https://www.instagram.com/semcorp_limited"
                target="_blank"
                rel="noreferrer"
                className="footer__social"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ color: "#ffffff" }}
                />
              </a>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Footer;
