import axios from "axios";

import {
  LANDING__CAROUSEL_URL,
  LANDING__ABOUT_URL,
  LANDING__SERVICES_URL,
} from "../utils/urls";

export const getLandingCarousel = () => {
  return axios({
    method: "GET",
    url: LANDING__CAROUSEL_URL,
  });
};

export const getLandingAbout = () => {
  return axios({
    method: "GET",
    url: LANDING__ABOUT_URL,
  });
};

export const getLandingServices = () => {
  return axios({
    method: "GET",
    url: LANDING__SERVICES_URL,
  });
};
