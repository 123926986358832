import axios from "axios";

import { ABOUT__URL } from "../utils/urls";

export const getAbout = () => {
  return axios({
    method: "GET",
    url: ABOUT__URL,
  });
};
