import React from "react";
import "./skeletonServiceListPage.scss";

const SkeletonServiceListPage = () => {
  return (
    <div className="skeletonServiceListPage">
      <div className="skeleton sslp__header"></div>

      <div className="sslp__desc">
        <div className="skeleton sslp__descText"></div>
        <div className="skeleton sslp__descText"></div>
        <div className="skeleton sslp__descText"></div>
        <div className="skeleton sslp__descText"></div>
        <div className="skeleton sslp__descText"></div>
      </div>

      <div className="sslp__list">
        <div className="sslp__listCard">
          <div className="skeleton sslp__listCardImg"></div>
          <div className="skeleton sslp__listCardText"></div>
        </div>
        <div className="sslp__listCard">
          <div className="skeleton sslp__listCardImg"></div>
          <div className="skeleton sslp__listCardText"></div>
        </div>
        <div className="sslp__listCard">
          <div className="skeleton sslp__listCardImg"></div>
          <div className="skeleton sslp__listCardText"></div>
        </div>
        <div className="sslp__listCard">
          <div className="skeleton sslp__listCardImg"></div>
          <div className="skeleton sslp__listCardText"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonServiceListPage;
