import React from "react";
import "./lineAnimation.scss";

const LineAnimation = ({ width }) => {
  return (
    <div
      className="repeartAnimation"
      style={{
        width: width ? width : "50px",
      }}
    >
      <div className="repeatLine1"></div>
      <div className="repeatLine2"></div>
    </div>
  );
};

export default LineAnimation;
