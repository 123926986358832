import React from "react";
import "./serviceCard.scss";

// import cardImg from "../../../assets/images/card/cardDemo.png";

// icon imports
import { IoIosArrowRoundForward } from "react-icons/io";

const ServiceCard = ({ data }) => {
  return (
    <div className="serviceCard">
      <div className="serviceCard__img">
        <img src={data?.IMAGE} alt="" loading="lazy" />
      </div>

      <div className="serviceCard__content">
        <div className="serviceCard__text">
          <span className="serviceCard__text__header">
            {data?.SERVICE_SUB_CATEGORY_NAME}
          </span>
          <span className="serviceCard__text__desc">
            {/* {data?.desc?.slice(0, 100)}... */}
          </span>
        </div>

        <div className="serviceCard__icon">
          <IoIosArrowRoundForward />
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
