import React, { useState } from "react";
import "./companyPolicies.scss";

// components
import CustomerPolicy from "./customerPolicy/CustomerPolicy";
import QualityPolicy from "./qualityPolicy/QualityPolicy";
import HealthSafetyPolicy from "./healthSafetyPolicy/HealthSafetyPolicy";
import EnvironmentalPolicy from "./environmentalPolicy/EnvironmentalPolicy";
import SkeletonAboutPolicis from "../../../../components/skeletons/pageSkeletons/aboutPageSkeletons/SkeletonAboutPolicis";

const CompanyPolicies = ({ data, loading }) => {
  const policies = [
    { id: 1, title: "Customer Care" },
    { id: 2, title: "Quality " },
    { id: 3, title: "Health & Safety " },
    { id: 4, title: "Environmental " },
  ];
  const [policiesData, setPoliciesData] = useState(data?.policyList);
  // console.log("dfgdfgdfgdfg", policiesData);

  const policyBtns = [
    policiesData.map((policy) => {
      return policy?.TITLE;
    }),
  ];
  console.log(policyBtns);

  const [activePolicy, setActivePolicy] = useState(policyBtns[0][0]);
  console.log(activePolicy);

  return (
    <>
      {loading ? (
        <SkeletonAboutPolicis />
      ) : (
        <div className="companyPolicies">
          <div className="companyPolicies__wrapper">
            <div className="companyPolicies__content">
              <h3 className="cSectionText white">Policies</h3>

              <div className="cp__buttons">
                {policyBtns[0]?.map((policy, i) => (
                  <div
                    key={policy.id}
                    className={
                      activePolicy === policy
                        ? "cp__button cp__buttonActive"
                        : "cp__button"
                    }
                    onClick={() => setActivePolicy(policy)}
                  >
                    <span className="cp__buttonCountText">{i + 1}</span>
                    <span className="cp__buttonText">{policy}</span>
                  </div>
                ))}
              </div>

              <div className="cp__content">
                {/* {
              {
                "Customer Care": <CustomerPolicy />,
                "Quality ": <QualityPolicy />,
                "Health & Safety ": <HealthSafetyPolicy />,
                "Environmental ": <EnvironmentalPolicy />,
              }[activePolicy]
            } */}

                {activePolicy === policyBtns[0][0] ? (
                  <CustomerPolicy data={policiesData[0]} loading={loading} />
                ) : activePolicy === policyBtns[0][1] ? (
                  <QualityPolicy data={policiesData[1]} loading={loading} />
                ) : activePolicy === policyBtns[0][2] ? (
                  <HealthSafetyPolicy
                    data={policiesData[2]}
                    loading={loading}
                  />
                ) : activePolicy === policyBtns[0][3] ? (
                  <EnvironmentalPolicy
                    data={policiesData[3]}
                    loading={loading}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyPolicies;
