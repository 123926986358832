import React, { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";

const LayoutAnimations = ({ children, propVarient, delaySec, oneTime }) => {
  const ref = useRef(null);

  // const isInView = useInView(ref, { once: false, threshold: 0.5 });
  const isInView = useInView(ref, {
    once: oneTime ? true : false,
    threshold: 0.5,
  });

  useEffect(() => {
    buttonVariants();
    rightToLeftWithDelay();
    downToUpWithDelay();
  }, [delaySec]);

  const scaleUp = (delaySec) => {
    const variants = {
      hidden: { opacity: 0, scale: 0.95 },
      visible: {
        opacity: 1,
        scale: 1,
        transition: {
          duration: 1,
          delay: delaySec * 0.2,
        },
      },
    };
    return variants;
  };

  const leftToRight = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.5,
        delay: 0.2,
      },
    },
  };

  const rightToLeft = {
    hidden: { opacity: 0, x: 100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.5,
        delay: 0.2,
      },
    },
  };

  const rightToLeftWithDelay = (delaySec) => {
    const variants = {
      hidden: { opacity: 0, x: 100 },
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 1.5,
          delay: delaySec * 0.15,
        },
      },
    };
    return variants;
  };

  const downToUp = {
    hidden: { opacity: 0, y: 100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        delay: 0.2,
      },
    },
  };

  const downToUpWithDelay = (delaySec) => {
    const variants = {
      hidden: { opacity: 0, y: 50 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 1.5,
          delay: delaySec * 0.3,
        },
      },
    };
    return variants;
  };

  const upToDown = {
    hidden: { opacity: 0, y: -100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        delay: 0.2,
      },
    },
  };

  const staggerContent = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1.5,
        delay: 0.2,
        staggerChildren: 0.8,
      },
    },
  };

  const buttonVariants = (delaySec) => {
    const variants = {
      hidden: { opacity: 0, y: 50 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          delay: 0.1 + delaySec * 0.2,
        },
      },
    };
    return variants;
  };

  return (
    <div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={
          propVarient === "scaleUp"
            ? scaleUp(delaySec)
            : propVarient === "leftToRight"
            ? leftToRight
            : propVarient === "rightToLeft"
            ? rightToLeft
            : propVarient === "rightToLeftWithDelay"
            ? rightToLeftWithDelay(delaySec)
            : propVarient === "downToUp"
            ? downToUp
            : propVarient === "downToUpWithDelay"
            ? downToUpWithDelay(delaySec)
            : propVarient === "upToDown"
            ? upToDown
            : propVarient === "staggerContent"
            ? staggerContent
            : propVarient === "buttonVariants"
            ? buttonVariants(delaySec)
            : null
        }
      >
        {children}
      </motion.div>
    </div>
  );
};

export default LayoutAnimations;
