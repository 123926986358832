import React from "react";
import "./customerPolicy.scss";
import parse from "html-react-parser";

const CustomerPolicy = ({ data, loading }) => {
  return (
    <div className="customerPolicy">
      <h3 className="customerPolicy__headerText">{data.TITLE}</h3>

      <div className="customerPolicy__content">
        {data.DESCRIPTION && parse(data.DESCRIPTION)}

        {/* <span className="customerPolicy__contentDescText">
          SEMCORP is unique in that we provide extraordinary customer care,
          unparalleled customer service, and industry support. Architects,
          designers, engineers, building contractors, and owners benefit from
          the SEMCORP team's valuable resources and extensive industry
          knowledge.
        </span>

        <div className="customerPolicy__contentList">
          <h3 className="customerPolicy__contentListTitle">
            SEMCORP works hand-in-hand with customers.
          </h3>

          <ul className="customerPolicy__contentListItems">
            <li className="customerPolicy__contentListItem">
              Field evaluations, recommendations, and application solutions are
              provided on a project-by-project and technology basis.
            </li>
            <li className="customerPolicy__contentListItem">
              Assisting with choosing products, specifications, installation,
              and related technologies.
            </li>
            <li className="customerPolicy__contentListItem">
              Attending pre-design meetings, helping to clarify specs, and
              recommending product options.
            </li>
            <li className="customerPolicy__contentListItem">
              Supporting you by offering detailed pre-installation instructions
              and procedures for getting high-quality results.
            </li>
            <li className="customerPolicy__contentListItem">
              Visiting the project to execute a mockup before proposing a
              solution.
            </li>

            <li className="customerPolicy__contentListItem">
              Analyzing to ensure the project's cost-effectiveness and quality.
            </li>
          </ul>
        </div>

        <span className="customerPolicy__contentSubHeaderText">
          We promise to continuously assess and improve our customer service
          guidelines.
        </span> */}
      </div>
    </div>
  );
};

export default CustomerPolicy;
