import React from "react";
import "./aboutPageSkeleton.scss";

const SkeletonAboutPolicis = () => {
  return (
    <div className="apSkeleton__container app__skeletonColorbg">
      <div className="apSkeleton__wrapper">
        <div className="apSkeleton__policy">
          <div className="skeletonType1 apSkeleton__policyHeader"></div>

          <div className="app__skeletonContent">
            <div className="skeletonType1 apSkeleton__policyBtn"></div>

            <div className="skeletonType1 apSkeleton__policyContent">
              <div className="apSkeleton__mDesc">
                <div className="skeleton apSkeleton__mDescText"></div>
                <div className="skeleton apSkeleton__mDescText"></div>
                <div className="skeleton apSkeleton__mDescText"></div>
                <div className="skeleton apSkeleton__mDescText"></div>
                <div className="skeleton apSkeleton__mDescText"></div>
                <div className="skeleton apSkeleton__mDescText"></div>
                <div className="skeleton apSkeleton__mDescText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonAboutPolicis;
