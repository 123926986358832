import React, { useEffect, useState } from "react";
import "./projectDetailsPage.scss";

// api services
import { getProjectDetails } from "../../../apis/projects.apis";

// img
import bg from "../../../assets/images/backgrounds/detailsPage__heroBg.png";

// components
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import SubHero from "../../../components/heroSections/subHero/SubHero";
import { useParams } from "react-router-dom";
import SkeletonProjectDetailsPage from "../../../components/skeletons/pageSkeletons/projectPageSkeleton/SkeletonProjectDetailsPage";

const ProjectDetailsPage = () => {
  const { projectSlug } = useParams();
  const [loading, setLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});

  useEffect(() => {
    setLoading(true);
    getProjectDetails(projectSlug)
      .then((res) => {
        setProjectDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [projectSlug]);
  // console.log(projectDetails);

  const [imgData, setImgData] = useState({ img: "", i: 0 });

  const viewImage = (img, i) => {
    setImgData({ img, i });
  };

  return (
    <AppLayout>
      <div className="projectDetailsPage">
        <SubHero
          pName={projectDetails?.PROJECT_NAME}
          pLocation={projectDetails?.PROJECT_ADDRESS}
          pTags={projectDetails?.projectSector}
          banner={bg}
          bg={true}
        />

        <div className="projectDetails__wrapper">
          <div className="pdw__container">
            {loading ? (
              <SkeletonProjectDetailsPage />
            ) : (
              <>
                <div className="projectDetails__infos">
                  <div className="pdi__container">
                    <div className="pdi__containerLeft">
                      <div className="pdicl__item">
                        <span className="pdicl__title">Owner</span>
                        <span className="pdicl__colon">:</span>
                        <span className="pdicl__desc">
                          {projectDetails?.PROJECT_OWNER || "N/A"}
                        </span>
                      </div>
                      <div className="pdicl__item">
                        <span className="pdicl__title">Engineer</span>
                        <span className="pdicl__colon">:</span>
                        <span className="pdicl__desc">
                          {projectDetails?.PROJECT_ENGINEER || "N/A"}
                        </span>
                      </div>

                      <div className="pdicl__item">
                        <span className="pdicl__title">Architecture</span>
                        <span className="pdicl__colon">:</span>
                        <span className="pdicl__desc">
                          {projectDetails?.PROJECT_ARCHITECTURE || "N/A"}
                        </span>
                      </div>

                      <div className="pdicl__item">
                        <span className="pdicl__title">Contractor</span>
                        <span className="pdicl__colon">:</span>
                        <span className="pdicl__desc">
                          {projectDetails?.PROJECT_CONTRACTOR || "N/A"}
                        </span>
                      </div>

                      <div className="pdicl__item">
                        <span className="pdicl__title">Client</span>
                        <span className="pdicl__colon">:</span>
                        <span className="pdicl__desc">
                          {projectDetails?.CLIENT || "N/A"}
                        </span>
                      </div>
                    </div>

                    <div className="pdi__containerRight">
                      <div className="pdicr__content">
                        <span className="pdicr__title">Project Scope :</span>

                        <span className="pdicr__list">
                          {projectDetails?.projectScop &&
                            projectDetails?.projectScop.map((scope, i) => (
                              <span key={i}>{scope}</span>
                            ))}
                        </span>
                      </div>

                      <div className="pdicr__content">
                        <div className="pdicr__item">
                          <span className="pdicr__title1">Area</span>
                          <span className="pdicr__desc">:</span>
                          <span className="pdicr__desc">
                            {projectDetails?.PROJECT_AREA || "N/A"}
                          </span>
                        </div>
                        <div className="pdicr__item">
                          <span className="pdicr__title1">Duration</span>
                          <span className="pdicr__desc">:</span>
                          <span className="pdicr__desc">
                            {projectDetails?.PROJECT_DURATION || "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="projectDetails__gallery">
                  {imgData.img && (
                    <div className="pgp__modal">
                      <div className="pgp__modal__content">
                        <div
                          className="pgp__modal__close"
                          onClick={() => setImgData({ img: "", i: 0 })}
                        >
                          <i className="fas fa-times"></i>
                        </div>

                        <div className="nextImg">
                          {imgData.i !== 0 && (
                            <i
                              className="fas fa-chevron-left"
                              onClick={() =>
                                // viewImage(data[imgData.i - 1].src, imgData.i - 1)
                                viewImage(
                                  projectDetails?.galleryList[imgData.i - 1]
                                    .GALLERY_IMG_PATH,
                                  imgData.i - 1
                                )
                              }
                            ></i>
                          )}
                        </div>

                        <img src={imgData.img} alt="modal" />

                        <div className="nextImg">
                          {imgData.i !==
                            projectDetails?.galleryList.length - 1 && (
                            <i
                              className="fas fa-chevron-right"
                              onClick={() =>
                                // viewImage(data[imgData.i + 1].src, imgData.i + 1)
                                viewImage(
                                  projectDetails?.galleryList[imgData.i + 1]
                                    .GALLERY_IMG_PATH,
                                  imgData.i + 1
                                )
                              }
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="pgp__container">
                    {projectDetails?.galleryList &&
                      projectDetails?.galleryList.map((img, i) => (
                        <div
                          className="pgp__item"
                          key={i}
                          onClick={() => viewImage(img?.GALLERY_IMG_PATH, i)}
                        >
                          <img src={img?.GALLERY_IMG_PATH} alt="gallery" />
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default ProjectDetailsPage;
