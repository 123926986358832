import React from "react";
import "./landingAboutSkeleton.scss";

const LandingAboutSkeleton = () => {
  return (
    <div className="about__loading">
      <div className="al__id">
        <div className="al__idl">
          <div className="skeleton al__header"></div>

          <div className="aldesc">
            <div className="skeleton al__desc"></div>
            <div className="skeleton al__desc"></div>
            <div className="skeleton al__desc"></div>
            <div className="skeleton al__desc"></div>
            <div className="skeleton al__desc"></div>
          </div>

          <div className="skeleton al__btn"></div>
        </div>

        <div className="al__idr">
          <div className="skeleton al__img"></div>
        </div>
      </div>
    </div>
  );
};

export default LandingAboutSkeleton;
