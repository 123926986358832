import React, { useState } from "react";
import "./careerFormPage.scss";
import { useLocation, useNavigate } from "react-router-dom";

// components
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import Wrapper from "../../../components/layouts/wrapper/Wrapper";
import SubHero from "../../../components/heroSections/subHero/SubHero";
import ButtonType2 from "../../../components/buttons/buttonType2/ButtonType2";

// API SERVICE
import { applyCareer } from "../../../apis/career.apis";

const CareerFormPage = () => {
  const navigate = useNavigate();

  // get position name prom props value
  // const location = useLocation();
  // const positionNameFromUrl = location.state.position;

  // get position name from url
  const url = window.location.href;
  const urlArr = url.split("/");
  const positionIdUrl = urlArr[urlArr.length - 2];
  const positionNameUrl = urlArr[urlArr.length - 3];
  // console.log(positionIdUrl, positionNameUrl);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [resume, setResume] = useState(null);
  const handleResume = (e) => {
    const file = e.target.files[0];
    const validTypes = ["application/pdf", "application/msword"];
    if (!validTypes.includes(file.type)) {
      setError("Invalid file type. Please upload pdf or doc file only");
      return;
    }
    setError(null);
    setResume(file);
  };

  const [formInputs, setFormInputs] = useState({
    FULLNAME: "",
    EMAIL: "",
    PHONE: "",
    ADDRESS: "",
    CITY: "",
    CURRENT_POSITION: "",
    COMPANY_NAME: "",
    CURRENT_SALARY: "",
    EXPECTED_SALARY: "",
  });

  const handleFormInputs = (name, e) => {
    setFormInputs({
      ...formInputs,
      [name]: e.target.value,
    });
  };

  const formValidation = () => {
    if (formInputs.FULLNAME.length < 3) {
      setError("Full name must be at least 3 characters");
      return false;
    }
    if (formInputs.PHONE.length < 11 || formInputs.PHONE.length > 11) {
      setError("Phone number must be at least 11 characters");
      return false;
    }
    if (formInputs.ADDRESS.length < 5) {
      setError("Address must be at least 5 characters");
      return false;
    }
    if (formInputs.CURRENT_POSITION.length < 3) {
      setError("Current position must be at least 3 characters");
      return false;
    }
    if (formInputs.COMPANY_NAME.length < 3) {
      setError("Company name must be at least 3 characters");
      return false;
    }
    if (!resume) {
      setError("Please upload your resume");
      return false;
    }
    return true;
  };

  const handleFormSubmition = (e) => {
    e.preventDefault();

    setLoading(true);

    if (!formValidation()) {
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("JOB_DESCRIPTION_ID", positionIdUrl);
    formData.append("FULLNAME", formInputs.FULLNAME);
    formData.append("EMAIL", formInputs.EMAIL);
    formData.append("PHONE", formInputs.PHONE);
    formData.append("ADDRESS", formInputs.ADDRESS);
    formData.append("CITY", formInputs.CITY);
    formData.append("CURRENT_POSITION", formInputs.CURRENT_POSITION);
    formData.append("COMPANY_NAME", formInputs.COMPANY_NAME);
    formData.append("CURRENT_SALARY", formInputs.CURRENT_SALARY);
    formData.append("EXPECTED_SALARY", formInputs.EXPECTED_SALARY);
    formData.append("File", resume);

    applyCareer(formData)
      .then((res) => {
        // console.log(res.data.message);
        setSuccessMessage(res.data.message);
        setLoading(false);

        setTimeout(() => {
          navigate("/confirmation");
        }, 1000);

        setFormInputs({
          FULLNAME: "",
          EMAIL: "",
          PHONE: "",
          ADDRESS: "",
          CITY: "",
          state: "",
          zipCode: "",
          CURRENT_POSITION: "",
          COMPANY_NAME: "",
          CURRENT_SALARY: "",
          EXPECTED_SALARY: "",
        });
      })
      .catch((err) => {
        // console.log(err);
        setError("Something went wrong");
        setLoading(false);
      });
  };

  return (
    <AppLayout>
      <div className="careerFormPage pageLayout">
        <SubHero
          banner={
            "https://images.pexels.com/photos/3760069/pexels-photo-3760069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          }
          title={"Application Form"}
        />

        <Wrapper>
          <div className="careerFormPage__container">
            <div className="cfp__header">
              <h1>{positionNameUrl}</h1>
            </div>

            <form
              action=""
              className="cfp__formContainer"
              onSubmit={handleFormSubmition}
            >
              <div className="cfp__inputContainer">
                <div className="input__group field">
                  <input
                    type="text"
                    className="input__field"
                    placeholder="Full Name"
                    name="fullName"
                    id="fullName"
                    required
                    value={formInputs.FULLNAME}
                    onChange={(e) => handleFormInputs("FULLNAME", e)}
                  />

                  <label htmlFor="fullName" className="input__label">
                    Full Name
                  </label>
                </div>

                <div className="input__group field">
                  <input
                    type="email"
                    className="input__field"
                    placeholder="Email"
                    name="email"
                    id="email"
                    required
                    value={formInputs.EMAIL}
                    onChange={(e) => handleFormInputs("EMAIL", e)}
                  />
                  <label htmlFor="email" className="input__label">
                    Email
                  </label>
                </div>

                <div className="input__group field">
                  <input
                    type="number"
                    className="input__field"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    id="phoneNumber"
                    required
                    value={formInputs.PHONE}
                    onChange={(e) => handleFormInputs("PHONE", e)}
                  />

                  <label htmlFor="phoneNumber" className="input__label">
                    Phone Number
                  </label>
                </div>

                <div className="input__group field">
                  <input
                    type="text"
                    className="input__field"
                    placeholder="Address"
                    name="address"
                    id="address"
                    required
                    value={formInputs.ADDRESS}
                    onChange={(e) => handleFormInputs("ADDRESS", e)}
                  />

                  <label htmlFor="address" className="input__label">
                    Address
                  </label>
                </div>

                {/* <div className="input__group field">
                  <input
                    type="text"
                    className="input__field"
                    placeholder="City"
                    name="city"
                    id="city"
                    required
                    value={formInputs.CITY}
                    onChange={(e) => handleFormInputs("CITY", e)}
                  />

                  <label htmlFor="city" className="input__label">
                    City
                  </label>
                </div> */}

                {/* <div className="cfp__doubleInput">
                  <div className="input__group field">
                    <input
                      type="text"
                      className="input__field"
                      placeholder="State"
                      name="state"
                      id="state"
                      required
                      value={formInputs.state}
                      onChange={(e) => handleFormInputs("state", e)}
                    />

                    <label htmlFor="state" className="input__label">
                      State
                    </label>
                  </div>

                  <div className="input__group field">
                    <input
                      type="text"
                      className="input__field"
                      placeholder="Zip Code"
                      name="zipCode"
                      id="zipCode"
                      required
                      value={formInputs.zipCode}
                      onChange={(e) => handleFormInputs("zipCode", e)}
                    />

                    <label htmlFor="zipCode" className="input__label">
                      Zip Code
                    </label>
                  </div>
                </div> */}

                <div className="cfp__doubleInput">
                  <div className="input__group field">
                    <input
                      type="text"
                      className="input__field"
                      placeholder="Current Employment"
                      name="currentEmployment"
                      id="currentEmployment"
                      required
                      value={formInputs.CURRENT_POSITION}
                      onChange={(e) => handleFormInputs("CURRENT_POSITION", e)}
                    />

                    <label htmlFor="currentEmployment" className="input__label">
                      Current Employment
                    </label>
                  </div>

                  <div className="input__group field">
                    <input
                      type="text"
                      className="input__field"
                      placeholder="Company Name"
                      name="companyName"
                      id="companyName"
                      required
                      value={formInputs.COMPANY_NAME}
                      onChange={(e) => handleFormInputs("COMPANY_NAME", e)}
                    />
                    <label htmlFor="companyName" className="input__label">
                      Company Name
                    </label>
                  </div>
                </div>

                <div className="cfp__doubleInput">
                  <div className="input__group field">
                    <input
                      type="number"
                      className="input__field"
                      placeholder="Current Salary"
                      name="currentSalary"
                      id="currentSalary"
                      // required
                      value={formInputs.CURRENT_SALARY}
                      onChange={(e) => handleFormInputs("CURRENT_SALARY", e)}
                    />
                    <label htmlFor="currentSalary" className="input__label">
                      Current Salary
                    </label>
                  </div>

                  <div className="input__group field">
                    <input
                      type="number"
                      className="input__field"
                      placeholder="Expected Salary"
                      name="expectedSalary"
                      id="expectedSalary"
                      // required
                      value={formInputs.EXPECTED_SALARY}
                      onChange={(e) => handleFormInputs("EXPECTED_SALARY", e)}
                    />
                    <label htmlFor="expectedSalary" className="input__label">
                      Expected Salary
                    </label>
                  </div>
                </div>
              </div>

              {/* <div className="cfp__message">
                <label htmlFor="" className="cfp__label">
                  Message
                </label>
                <textarea
                  name=""
                  id=""
                  rows="5"
                  className="cfp__textarea"
                  placeholder="Write your message here..."
                ></textarea>
              </div> */}

              <div className="cfp__fileInput">
                <label htmlFor="" className="cfp__label">
                  Resume
                </label>

                <input
                  type="file"
                  className="cf__fileInput"
                  onChange={(e) => handleResume(e)}
                />
              </div>

              {/* <div className="cfp__checks">
                <div className="cfp__check">
                  <input type="checkbox" />
                  <label htmlFor="">I agree to the terms and conditions</label>
                </div>

                <div className="cfp__check">
                  <input type="checkbox" />
                  <label htmlFor="">
                    I am authorized to work in this country for any employer,
                    now and in the future.
                  </label>
                </div>

                <div className="cfp__check">
                  <input type="checkbox" />
                  <label htmlFor="">
                    I am authorized to work in this country for my present
                    employer only.
                  </label>
                </div>

                <div className="cfp__check">
                  <input type="checkbox" />
                  <label htmlFor="">
                    I require sponsorship to work in this country, either now or
                    in the future.
                  </label>
                </div>
              </div> */}

              {error && (
                <div className="messageText error">
                  <p>{error}</p>
                </div>
              )}

              {successMessage && (
                <div className="messageText success">
                  <p>{successMessage}</p>
                </div>
              )}

              <div className="cfp__submit" type="submit">
                {loading ? (
                  <ButtonType2 btnName="Submitting..." />
                ) : (
                  <ButtonType2 btnName="Submit" />
                )}
              </div>
            </form>
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default CareerFormPage;
