import React from "react";
import "./projectPageSkeleton.scss";

const SkeletonProjectDetailsPage = () => {
  return (
    <>
      <div className="skeleton__pdp__info">
        <div className="skeleton__pdp__infoContainer">
          <div className="skeleton__pdp__ic__left">
            <div className="skeletonType1 skeleton__pdp__ic__leftItem"></div>
            <div className="skeletonType1 skeleton__pdp__ic__leftItem"></div>
            <div className="skeletonType1 skeleton__pdp__ic__leftItem"></div>
            <div className="skeletonType1 skeleton__pdp__ic__leftItem"></div>
            <div className="skeletonType1 skeleton__pdp__ic__leftItem"></div>
          </div>

          <div className="skeleton__pdp__ic__right">
            <div className="skeletonType1 skeleton__pdp__ic__RightItem"></div>
            <div className="skeletonType1 skeleton__pdp__ic__RightItem"></div>
          </div>
        </div>
      </div>

      <div className="skeleton__pdp__gallery">
        <div className="skeleton skeleton__pdp__galleryImg"></div>
        <div className="skeleton skeleton__pdp__galleryImg"></div>
        <div className="skeleton skeleton__pdp__galleryImg"></div>
        <div className="skeleton skeleton__pdp__galleryImg"></div>
        <div className="skeleton skeleton__pdp__galleryImg"></div>
        <div className="skeleton skeleton__pdp__galleryImg"></div>
      </div>
    </>
  );
};

export default SkeletonProjectDetailsPage;
