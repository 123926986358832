import React from "react";
import "./wrapper.scss";

const Wrapper = ({ children }) => {
  return (
    <main className="wrapper">
      <section className="wrapper__child">{children}</section>
    </main>
  );
};

export default Wrapper;
