import axios from "axios";

import {
  SERVICE__SURVICE_URL,
  SERVICE__MAINCATEGORY_URL,
  SERVICE__LIST_URL,
} from "../utils/urls";

export const getServiceMainCategory = () => {
  return axios({
    method: "GET",
    url: SERVICE__MAINCATEGORY_URL,
  });
};

export const getServiceList = () => {
  return axios({
    method: "GET",
    url: SERVICE__LIST_URL,
  });
};

export const getSubServiceList = (catSlag, subCatSlag) => {
  return axios({
    method: "GET",
    url:
      SERVICE__SURVICE_URL +
      `/Allservice?cat_slag=${catSlag}&subcat_slag=${subCatSlag}`,
  });
};
// Allservice?cat_slag=general-contracting&subcat_slag=civil-construction
