import React from "react";
import "./careerPageSkeleton.scss";
import Wrapper from "../../../layouts/wrapper/Wrapper";

const SkeletonCareerDetailsPage = () => {
  return (
    <Wrapper>
      <div className="skeleton__careerDetailsPage">
        <div className="scdp__headerBtn">
          <div className="skeleton scdp__header"></div>
          <div className="skeleton scdp__btn"></div>
        </div>

        <div className="scdp__infos">
          <div className="skeleton scdp__info"></div>
          <div className="skeleton scdp__info"></div>
          <div className="skeleton scdp__info"></div>
          <div className="skeleton scdp__info"></div>
          <div className="skeleton scdp__info"></div>
        </div>

        <div className="scdp__reaquirments">
          <div className="skeleton scdp__reaquirmentsHeader"></div>

          <div className="scdp__reaquirmentsContainer">
            <div className="skeleton scdp__rLine"></div>
            <div className="skeleton scdp__rLine"></div>
            <div className="skeleton scdp__rLine"></div>
            <div className="skeleton scdp__rLine"></div>
            <div className="skeleton scdp__rLine"></div>
          </div>
        </div>

        <div className="scdp__reaquirments">
          <div className="skeleton scdp__reaquirmentsHeader"></div>

          <div className="scdp__reaquirmentsContainer">
            <div className="skeleton scdp__rLine"></div>
            <div className="skeleton scdp__rLine"></div>
            <div className="skeleton scdp__rLine"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SkeletonCareerDetailsPage;
