import React from "react";
import "../customerPolicy/customerPolicy.scss";
import parse from "html-react-parser";

// components

const HealthSafetyPolicy = ({ data, loading }) => {
  return (
    <div className="customerPolicy">
      <h3 className="customerPolicy__headerText">{data.TITLE}</h3>

      <div className="customerPolicy__content">
        {data.DESCRIPTION && parse(data.DESCRIPTION)}

        {/* <span className="customerPolicy__contentDescText">
          SEMCORP Limited is dedicated to avoiding the accidental loss of any of
          its resources, including people and physical assets
        </span>

        <div className="customerPolicy__contentList">
          <ul className="customerPolicy__contentListItems">
            <li className="customerPolicy__contentListItem">
              To fulfill this commitment to protect both people and property,
              management will provide and maintain a safe and healthy work
              environment that adheres to industry standards and complies with
              legislative requirements, as well as strive to eliminate any
              foreseeable hazards that could cause property damage, accidents,
              or personal injury or illness.
            </li>

            <li className="customerPolicy__contentListItem">
              We understand that responsibility for health and safety is shared.
              All employees will have equal responsibility for reducing
              accidents in our facilities and on our work sites. The company's
              health and safety document will explicitly explain safe work
              practices and job processes, which all employees must follow.
            </li>

            <li className="customerPolicy__contentListItem">
              Accidental losses can be reduced by effective management and
              active employee participation. Safety is the responsibility of all
              managers, supervisors, employees, and contractors.
            </li>
          </ul>
        </div>

        <span className="customerPolicy__contentDescText">
          All management actions will adhere to the company's safety criteria
          for facility and equipment planning, operation, and maintenance. All
          personnel will perform their duties according to approved protocols
          and safe work practices.
        </span> */}
      </div>
    </div>
  );
};

export default HealthSafetyPolicy;
