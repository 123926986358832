import React from "react";
import "./serviceCardSkeleton.scss";

const ServiceCardSkeleton = () => {
  return (
    <div className="serviceCardSkeleton">
      <div className="serviceCardSkeleton__image skeleton"></div>
      <div className="serviceCardSkeleton__title skeleton"></div>
    </div>
  );
};

export default ServiceCardSkeleton;
