import axios from "axios";

import {
  CAREER_JOBLIST_URL,
  CAREER_DETAILS_URL,
  CAREER_APPLY_URL,
} from "../utils/urls";

export const getJobList = () => {
  return axios({
    method: "GET",
    url: CAREER_JOBLIST_URL,
  });
};

export const getJobDetails = (id) => {
  return axios({
    method: "GET",
    url: CAREER_DETAILS_URL + `/${id}`,
  });
};

export const applyCareer = (data) => {
  return axios({
    method: "POST",
    url: CAREER_APPLY_URL,
    data: data,
  });
};
