import React from "react";
import "./ourPeople.scss";
import parse from "html-react-parser";

// img
import SkeletonAboutPeople from "../../../../components/skeletons/pageSkeletons/aboutPageSkeletons/SkeletonAboutPeople";

const OurPeople = ({ data, loading }) => {
  return (
    <>
      {loading ? (
        <SkeletonAboutPeople />
      ) : (
        <div className="ourPeople">
          <div className="ourPeople__container">
            <div className="ourPeople__img">
              <img src={data?.IMAGE} alt="" />
            </div>

            <div className="ourPeople__info">
              <h3 className="cSectionText">{data?.BANNER_TITLE}</h3>

              {data?.DESCRIPTION && parse(data?.DESCRIPTION)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OurPeople;
