import React from "react";
import "./buttonType2.scss";

const ButtonType2 = ({ btnName }) => {
  return (
    <button className="twenty-one">
      <span>{btnName}</span>
    </button>
  );
};

export default ButtonType2;
