import React, { useEffect, useState } from "react";
import "./landingServices.scss";
import ButtonType2 from "../../buttons/buttonType2/ButtonType2";
import ServiceBtn from "../../buttons/serviceBtn/ServiceBtn";
import SkeletonLandingSvCard from "../../skeletons/cards/skeletonLandingSvCard/SkeletonLandingSvCard";

import { getLandingServices } from "../../../apis/landing.apis";
import SkeletonLendingService from "../../skeletons/skeletonLendingService/SkeletonLendingService";

const LandingServices = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentImage, setCurrentImage] = useState(services[0]?.BANNER_IMAGE);

  useEffect(() => {
    setLoading(true);
    getLandingServices()
      .then((res) => {
        setServices(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  // console.log(services);

  const images = [
    {
      id: 1,
      title: "General Contracting",
      slug: "general-contracting",
      image:
        "https://images.pexels.com/photos/1145434/pexels-photo-1145434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 2,
      title: "Retrofitting",
      slug: "retrofitting",
      image:
        "https://images.pexels.com/photos/16532833/pexels-photo-16532833/free-photo-of-silhouettes-of-cranes.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 3,
      title: "MEP",
      slug: "mep",
      image:
        "https://images.pexels.com/photos/1094767/pexels-photo-1094767.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 4,
      title: "Speciality Technical Work",
      slug: "speciality-technical-work",
      image:
        "https://images.pexels.com/photos/3158651/pexels-photo-3158651.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 5,
      title: "Fit Out",
      slug: "fit-out",
      image:
        "https://images.pexels.com/photos/10161111/pexels-photo-10161111.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 6,
      title: "Turnkey",
      slug: "turnkey",
      image:
        "https://images.pexels.com/photos/2566850/pexels-photo-2566850.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
  ];
  console.log(currentImage);

  return (
    <div className="landingServices">
      {loading ? (
        <SkeletonLendingService />
      ) : (
        <div className="landingServices__container">
          <div
            className="lsc__layer"
            style={{
              // backgroundImage:
              //   window.innerWidth > 991 ? `url(${currentImage})` : null,

              backgroundImage:
                window.innerWidth > 991 && currentImage
                  ? `url(${currentImage})`
                  : null,

              // backgroundImage: `url(${currentImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              transition: "all 0.5s ease",
            }}
          >
            {/* <img src={currentImage} alt="" /> */}
          </div>

          {services.map((item, index) => {
            return (
              <a
                className="lsc__service"
                href={`/services/${item?.SLAG}`}
                key={index}
                onMouseEnter={() => setCurrentImage(item?.BANNER_IMAGE)}
                style={{
                  // backgroundImage:
                  //   window.innerWidth < 991
                  //     ? `url(${item?.BANNER_IMAGE})`
                  //     : null,

                  backgroundImage:
                    window.innerWidth < 991 && item?.BANNER_IMAGE
                      ? `url(${item?.BANNER_IMAGE})`
                      : null,

                  // backgroundImage: `url(${item?.BANNER_IMAGE})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  transition: "all 0.5s ease",
                }}
              >
                <h3>{item?.SERVICE_CATEGORY_NAME}</h3>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LandingServices;
