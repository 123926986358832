import axios from "axios";

import { ALL_PROJECT__URL, PROJECT_DETAILS_URL } from "../utils/urls";

export const getAllProjects = () => {
  return axios({
    method: "GET",
    url: ALL_PROJECT__URL,
  });
};

export const getProjectDetails = (slug) => {
  return axios({
    method: "GET",
    url: `${PROJECT_DETAILS_URL}${slug}`,
  });
};
